import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useStyles } from "./Style";
import { Stack } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import clsx from "clsx";

export default function SelectWithOptions({
  placeholder,
  options,
  onChange,
  onBlur,
  className,
  value,
  Label,
  error,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Stack sx={{ width: "100%" }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        className={classes.SelectLable}
      >
        {Label}
      </InputLabel>
      <Autocomplete
        {...props}
        id="asynchronous-demo"
        open={open}
        fullWidth={true}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={false}
        clearIcon={false}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        getOptionLabel={(option) => option?.name || ""}
        options={options || []}
        className={clsx(classes.Select, className)}
        onChange={onChange}
        value={value}
        classes={{ paper: classes.paper }}
        renderInput={(params) => (
          <TextField
            className={classes.input}
            placeholder={placeholder}
            onBlur={onBlur}
            {...params}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {error ? <p className={classes.errorMessage}>{error}</p> : ""}
    </Stack>
  );
}
