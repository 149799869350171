const createAdapter = (fields = []) => {
  return (data) => {
    const base = {
      id: data.id || null,
      salaryId: data.salaryId,
      // nextAction: data.id ? 'UPDATE' : 'CREATE'
    }

    // Add fields if they are present in the data
    fields.forEach((field) => {
      if (data[field] !== undefined) {
        base[field] = data[field]
      }
    })

    return base
  }
}

const createValidator = (fields = []) => {
  return (data) => {
    const errors = {}

    fields.forEach((field) => {
      if (!data[field]) {
        errors[field] = 'This field is required'
      }
    })

    return errors
  }
}

export const proofOfIncome = {
  BelizeBank: {
    ownJobLetter: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    },
    sendRequest: {
      adapter: createAdapter(['employerEmail', 'jobLetterId']),
      validator: createValidator(['employerEmail'])
    },
    uploadLater: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    },
    contract: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    }
  },
  anotherBank: {
    only: { adapter: createAdapter(), validator: createValidator(['bankId']) },
    ownJobLetter: {
      adapter: createAdapter(['bankId', 'file', 'jobLetterId']),
      validator: createValidator(['bankId'])
    },
    sendRequest: {
      adapter: createAdapter(['bankId', 'employerEmail', 'jobLetterId']),
      validator: createValidator(['employerEmail', 'bankId'])
    },
    uploadLater: {
      adapter: createAdapter(['bankId', 'file', 'jobLetterId']),
      validator: createValidator(['bankId'])
    },
    contract: {
      adapter: createAdapter(['bankId', 'file', 'jobLetterId']),
      validator: createValidator(['bankId'])
    }
  },
  cashOrCheck: {
    ownJobLetter: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    },
    sendRequest: {
      adapter: createAdapter(['employerEmail', 'jobLetterId']),
      validator: createValidator(['employerEmail'])
    },
    uploadLater: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    },
    contract: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    }
  },
  selfEmployed: {
    only: { adapter: createAdapter(), validator: createValidator(['salaryId']) }
  },
  contractor: {
    ownJobLetter: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    },
    sendRequest: {
      adapter: createAdapter(['employerEmail', 'jobLetterId']),
      validator: createValidator(['employerEmail'])
    },
    uploadLater: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    },
    contract: {
      adapter: createAdapter(['file', 'jobLetterId']),
      validator: createValidator()
    }
  }
}

export const SALARY_PAYMENTS = {
  1: 'BelizeBank',
  2: 'anotherBank',
  3: 'cashOrCheck',
  4: 'selfEmployed',
  5: 'contractor'
  // default: 'default',
}

export const JOB_LETTERS = {
  1: 'ownJobLetter', // I have my own job letter issued within the last 6 months
  2: 'sendRequest', // Please send the request to my employer
  3: 'uploadLater', // I will request my employment letter directly from my Employer and upload it later
  4: 'contract', // I have a copy of my contract
  only: 'only'
}
