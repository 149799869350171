import axios from 'axios'
import { endpoints } from './endpoints.js'

export default class JwtServiceCreditCard {
  #jwtConfig = { ...endpoints }

  #isAlreadyFetchingAccessToken = false

  #subscribers = []
  #axiosInstanceCC
  constructor(jwtOverrideConfig = {}) {
    this.#jwtConfig = { ...this.#jwtConfig }

    this.#axiosInstanceCC = axios.create()

    this.#axiosInstanceCC.interceptors.request.use(
      (config) => {
        const accessToken = this.#getTokenCC()

        if ( accessToken ) {
          config.headers.Authorization = `${this.#jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    this.#axiosInstanceCC.interceptors.response.use(
      async (response) => {
        if (
          response &&
          response.data &&
          response.data.jwt &&
          response.data.jwt.length > 0
        ) {
          await this.setAsyncToken(response.data.jwt).finally(() => {
            return response
          })
        }

        return response
      },
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          console.info('Your session has expired! Please log in again.')
          if (!this.#isAlreadyFetchingAccessToken) {
            this.#isAlreadyFetchingAccessToken = true
            this.getRefreshToken().then((r) => {
              this.#isAlreadyFetchingAccessToken = false

              // ** Update accessToken in store
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          return new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.#jwtConfig.tokenType
              } ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  #getTokenCC() {
    return sessionStorage.getItem(this.#jwtConfig.tokenKeyName)
  }

  onAccessTokenFetched(accessToken) {
    this.#subscribers = this.#subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.#subscribers.push(callback)
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.#jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    sessionStorage.setItem(this.#jwtConfig.tokenKeyName, value)
  }

  setAsyncToken(value) {
    return new Promise((resolve) => {
      sessionStorage.setItem(this.#jwtConfig.tokenKeyName, value)
      resolve()
    })
  }

  setRefreshToken(value) {
    sessionStorage.setItem(this.#jwtConfig.storageRefreshTokenKeyName, value)
  }

  #simulationHTTP(payload) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: { ...payload } })
      }, 2000)
    })
  }

  // ! Services credit-card (START)
  authenticate(token) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.authentication, token, {
      headers: {
        'Content-Type': 'application/json',
        // allow * for CORS
        // 'Access-Control-Allow-Origin': '*',
        Authorization: ``
      }
    })
    // return this.#simulationHTTP({
    //   token
    // })
  }

  hasApplicationInProgress() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.application)
  }

  getApplicationDocs() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.applicationDocsGET)
  }

  getADTypesList() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.applicationDocsTypesListGET)
  }

  postApplicationDocs(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.applicationDocsPOST, body)
  }

  deleteApplicationDocs(body) {
    // DELETE has no 'body' like POST or PUT
    return this.#axiosInstanceCC.delete(this.#jwtConfig.applicationDocsDELETE, { data: body })
  }

  personalInformationReview1() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.personalInformationReviewGET)
  }

  pirCheckPhone(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirCheckPhonePOST, body)
  }

  pirConfirmationPhone(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirConfirmationPhonePOST, body)
  }

  pirResendCodePhone() {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirResendCodePhonePOST)
  }

  pirCheckEmail(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirCheckEmailPOST, body)
  }

  pirConfirmationEmail(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirConfirmationEmailPOST, body)
  }

  pirResendCodeEmail() {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirResendCodeEmailPOST)
  }

  pirUploadSSNDocument(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.pirUploadSSNPOST, body)
  }

  getAdditionalApplicantsInfo() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.additionalApplicantsGET)
  }

  getApplicantsTypeCatalog() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.applicantTypeCatalogGET)
  }

  getDistrictsCatalog() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.districtsCatalogGET)
  }

  getUrbanAreasCatalog(param) {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.urbanAreasCatalogGET}/${param}`)
  }

  getRelationshipsCatalog() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.relationshipsCatalogGET)
  }

  getCountriesCatalog() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.countriesCatalogGET)
  }

  getStatesCatalog(param) {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.statesCatalogGET}/${param}`)
  }

  getOccupantsCatalog() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.occupantsCatalogGET)
  }

  getTitlePersonCatalog() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.titlePersonCatalogGET)
  }

  postAdditionalApplicants(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.additionalApplicantsPOST, body)
  }

  updateAdditionalApplicants(body) {
    return this.#axiosInstanceCC.put(this.#jwtConfig.additionalApplicantsPUT, body)
  }

  getUserPreferenceForAdditionalApplicants () {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.additionalApplicantsGET}/reject`)
  }

  decisionForAddApplicants (decision) {
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.additionalApplicantsGET}/reject`, decision)
  }

  getMyRequestLimit() {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.limitRequest}/requested-limit`)
  }

  async getCreditCards() {
    const storedCatalog = sessionStorage.getItem('creditCardsCatalog');
    if (storedCatalog) return Promise.resolve({ data: JSON.parse(storedCatalog) });

      return this.#axiosInstanceCC.get(this.#jwtConfig.creditCards).then(response => {
        // sessionStorage.setItem('creditCardsCatalog', JSON.stringify(response.data));
        return response
      })

  }

  gerCardDetailsById(id) {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.cardDetailsById}/${id}`)
  }

  startApplication(params) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.application, params)
  }

  getApplicationStep() {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.application}/step`)
  }

  updateApplicationStep(step) {
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.application}/step`, { step })
  }

  getCreditLimitRequestData() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.limitRequest)
  }

  getCreditLimitRequestPromoCode(promoCode) {
    if (!promoCode) {
      console.error('Promo code is required')
      return
    }

    return this.#axiosInstanceCC.get(`${this.#jwtConfig.promoCode}/${promoCode}`)
  }

  getCreditLimitRequestSelectedCard(cardId) {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.creditCards}/${cardId}`)
  }

  getInsuranceList() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.insurance)
  }

  getLimitTypes() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.limitTypes)
  }

  getLimitDurations() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.limitDurations)
  }

  getUserCreditCardInfo() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.userCreditCardsInfo)
  }
  startLimitIncrease(params) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.application, params)
  }

  getSelectedCardID() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.selectedCardID)
  }

  createCreditLimitRequest(requestData) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.limitRequest, requestData)
  }

  updateCreditLimitRequest(requestData) {
    return this.#axiosInstanceCC.put(this.#jwtConfig.limitRequest, requestData)
  }

  updateCurrentCardTypeInApplication(requestData) {
    return this.#axiosInstanceCC.put(this.#jwtConfig.updateCredtiCardType, requestData)
  }

  getMonthlyIncome() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.monthlyIncome)
  }

  createMonthlyIncome(data) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.monthlyIncome, data)
  }

  updateMonthlyIncome(data) {
    return this.#axiosInstanceCC.put(this.#jwtConfig.monthlyIncome, data)
  }

  getMyObligations() {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.monthlyExpenses}/bbl-obligations`)
  }

  getMonthlyExpensesTypes() {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.monthlyExpenses}/types-list`)
  }

  getMyMonthlyExpenses() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.monthlyExpenses)
  }

  createExpenseRecord(data) {
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.monthlyExpenses}/create`, data)
  }

  updateExpenseRecord(data) {
    return this.#axiosInstanceCC.put(`${this.#jwtConfig.monthlyExpenses}`, data)
  }

  deleteExpenseRecord(params) {
    return this.#axiosInstanceCC.delete(`${this.#jwtConfig.monthlyExpenses}/delete`, { data: params })
  }

  getProofOfIncome() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.proofOfIncomeGET)
  }

  getSalaryCatalog(filter = '') {
    return this.#axiosInstanceCC.get(this.#jwtConfig.salaryCatalogGET + filter)
  }

  getJobLetterCatalog(filter = '') {
    return this.#axiosInstanceCC.get(this.#jwtConfig.jobLetterCatalogGET + filter)
  }

  getBankCatalog(filter = '') {
    return this.#axiosInstanceCC.get(this.#jwtConfig.externalBankCatalogGET + filter)
  }

  postProofOfIncome(body) {
    return this.#axiosInstanceCC.post(this.#jwtConfig.proofOfIncomePOST, body)
  }

  updateProofOfIncome(body) {
    return this.#axiosInstanceCC.put(this.#jwtConfig.proofOfIncomePUT, body)
  }

  getADMissingDocs() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.adMissingDocsGET)
  }

  getMissingDocs() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.missingDocs)
  }

  getCustomerHasSSB() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.customerHasSSBGET)
  }

  getADCheckRequirements() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.adCheckRequirementsGET)
  }

  getADAssignLender() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.adAssignLenderGET)
  }

  getADComplete() {
    return this.#axiosInstanceCC.get(this.#jwtConfig.adCompleteGET)
  }
  
  //Applications
  getApplicationsHistory(){
    return this.#axiosInstanceCC.get(this.#jwtConfig.applicationsHistoryGET)
  }
  getMyApplications (type){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.applicationsHistoryGET}/${type}`)
  }
  getResumeApplication(id){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.resumeApplicationGET}/${id}`)
  }
  getScheduleAppointment () {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.creditCardEndpoint}/signing-appointment`)
  }
  getPickupBranch () {
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.creditCardEndpoint}/application/pickup-branch`)
  }
  updatePickupBranch (data) {
    return this.#axiosInstanceCC.put(`${this.#jwtConfig.creditCardEndpoint}/application/pickup-branch`, data)
  }
  updateScheduleAppointment (data) {
    return this.#axiosInstanceCC.put(`${this.#jwtConfig.creditCardEndpoint}/signing-appointment`, data)
  }

  getResumeApplicationCoApp(id){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.resumeApplicationGET}/co-applicant/${id}`)
  }

  //Sign Documents
  getSignDocuments(){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.signDocumentsGET}`)
  }
  postSignDocuments(data) {
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.signDocumentsGET}`, data)
  }
  getSignBranches(){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.signBranchesGET}`)
  }

  postPickupBranch(data){
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.pickupBranchPOST}`, data)
  }

  postSigningAppointment(data){
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.signingAppointmentPOST}`, data)
  }

  sendScheduleAppointment(data){
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.signingAppointmentPOST}`, data)
  }

  //Standing Order
  postStandingOrder(data){
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.standingOrder}`, data)
  }
  getStandingOrderAccountList(){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.standingOrder}/debit-accounts-list`)
  }
  getPaymentTypeList(){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.standingOrder}/payment-types-list`)
  }
  getPaymentCommence(){
    return this.#axiosInstanceCC.get(`${this.#jwtConfig.standingOrder}/payment-commences-list`) 
  }

  rejectStandingOrder (data) {
    return this.#axiosInstanceCC.post(`${this.#jwtConfig.standingOrder}/decline`)
  }

  // ! Services credit-card (END)
}
