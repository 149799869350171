import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

export const getProofOfIncomeInfoCall = createAsyncThunk('appProofOfIncome/info',  async () => {
    const response = await UseJwt.getProofOfIncome()
    return response.data
})

export const salaryCatalogCall = createAsyncThunk('appProofOfIncome/salaryCatalogCall', async () => {
    const response = await UseJwt.getSalaryCatalog()
    return response.data
})

export const jobLetterCatalogCall = createAsyncThunk('appProofOfIncome/jobLetterCatalogCall', async () => {
    const response = await UseJwt.getJobLetterCatalog()
    return response.data
})

export const bankCatalogCall = createAsyncThunk('appProofOfIncome/bankCatalogCall', async (_, { rejectWithValue }) => {
    const response = await UseJwt.getBankCatalog()
    return response.data?.banks || []
})

export const postProofOfIncomeCall = createAsyncThunk('appProofOfIncome/postProofOfIncomeCall', async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await UseJwt.postProofOfIncome(body).finally(() => dispatch(getProofOfIncomeInfoCall()))
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while creating proof of income')
      )
    }
  }
)

export const updateProofOfIncomeCall = createAsyncThunk('appProofOfIncome/updateProofOfIncomeCall', async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await UseJwt.updateProofOfIncome(body).finally(() => dispatch(getProofOfIncomeInfoCall()))
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error ||
          initialError('Something went wrong while updating proof of income')
      )
    }
  }
)

export const appProofOfIncome = createSlice({
  name: 'appProofOfIncome',
  initialState: {
    pending: true,
    errors: {
      salaryId: null,
      jobLetterId: null,
      bankId: null,
      employerEmail: null,
      file: null
    },
    data: {},
    formData: {
      salaryId: '',
      jobLetterId: '',
      bankId: '',
      employerEmail: '',
      file: null,
    },
    catalogs: {
      salaryCatalog: [],
      jobLetterCatalog: [],
      bankCatalog: []
    },
    nextAction: 'CREATE',
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload }
    },
    setErrors: (state, action) => {
      state.errors = { ...state.errors, ...action.payload }
    }
  },
  extraReducers: {
    [salaryCatalogCall.fulfilled]: (state, action) => {
      state.catalogs.salaryCatalog = action.payload
    },
    [jobLetterCatalogCall.fulfilled]: (state, action) => {
      state.catalogs.jobLetterCatalog = action.payload
    },
    [bankCatalogCall.fulfilled]: (state, action) => {
      state.catalogs.bankCatalog = action.payload
    },
    [getProofOfIncomeInfoCall.fulfilled]: (state, action) => {
      state.data = action.payload
      const file = action.payload.docDetails

      state.formData = {
        salaryId: action.payload.salaryId,
        jobLetterId: action.payload.jobLetterId,
        bankId: action.payload.bankId,
        employerEmail: action.payload.employerEmail,
        id: action.payload.id
      }

      if (file && file.id) {
        state.formData.file = {
          id: file.id,
          base64: file.documentFile || null,
          name: file.documentName || ''
        }
      }

      state.nextAction = 'UPDATE'
      state.pending = false
    },
    [getProofOfIncomeInfoCall.rejected]: (state) => {
      state.pending = false
      state.nextAction = 'CREATE'
    },
    [postProofOfIncomeCall.pending]: (state) => {
      state.pending = true
    },
    [postProofOfIncomeCall.fulfilled]: (state) => {
      state.pending = false
    },
    [postProofOfIncomeCall.rejected]: (state) => {
      state.pending = false
    },
    [updateProofOfIncomeCall.pending]: (state) => {
      state.pending = true
    },
    [updateProofOfIncomeCall.fulfilled]: (state) => {
      state.pending = false
    },
    [updateProofOfIncomeCall.rejected]: (state) => {
      state.pending = false
    }
  }
})

export const { setFormData, setErrors } = appProofOfIncome.actions

export default appProofOfIncome.reducer
