import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'
import { missingDocsAdapter } from '../adapters/missind-docs'

const initialError = (message = '') => ({
  statusCode: null,
  name: '',
  message
})

// export para consultar los documentos pendientes de subir
export const getMissingDocs = createAsyncThunk('appStepUploadMissingDocs/getMissingDocs', async (_, { rejectWithValue }) => {
  try {
    const response = await UseJwt.getMissingDocs()
    return missingDocsAdapter(response.data)
  } catch (e) {
    return rejectWithValue(e.response?.data?.error)
  }
})

export const ssnDocumentPending = createAsyncThunk('appStepUploadMissingDocs/ssnDocumentPending', async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getCustomerHasSSB()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adMissingDocsCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADMissingDocs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADMissingDocs()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const checkApplicationStatus = createAsyncThunk('appStepUploadMissingDocs/getADCheckRequirements', async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADCheckRequirements()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adAssignLenderCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADAssignLender',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADAssignLender()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const adCompleteCall = createAsyncThunk(
  'appStepUploadMissingDocs/getADComplete',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getADComplete()
      return response.data
    } catch (e) {
      return rejectWithValue(
        e.response?.data?.error || initialError('Something went wrong')
      )
    }
  }
)

export const appStepUploadMissingDocs = createSlice({
  name: 'appStepUploadMissingDocs',
  initialState: {
    pending: true,
    customerHasSSB: null,
    missingDocs: [],
    checkRequirements: null,
    adComplete: null,
    adAssignLender: null,
  },
  reducers: {},
  extraReducers: {
    [getMissingDocs.pending]: (state) => {
      state.pending = true
    },
    [getMissingDocs.fulfilled]: (state, action) => {
      state.pending = false
      state.missingDocs = action.payload
    },
    [ssnDocumentPending.pending]: (state) => {
      state.pending = true
    },
    [ssnDocumentPending.fulfilled]: (state, action) => {
      state.pending = false
      state.customerHasSSB = action.payload
    },
    [checkApplicationStatus.pending]: (state) => {
      state.pending = true
    },
    [checkApplicationStatus.fulfilled]: (state, action) => {
      state.pending = false
      state.checkRequirements = action.payload
    },
    [adCompleteCall.pending]: (state) => {
      state.pending = true
    },
    [adCompleteCall.fulfilled]: (state, action) => {
      state.pending = false
      state.adComplete = action.payload
    },
    [adAssignLenderCall.pending]: (state) => {
      state.pending = true
    },
    [adAssignLenderCall.fulfilled]: (state, action) => {
      state.pending = false
      state.adAssignLender = action.payload
    },
  }
})

export default appStepUploadMissingDocs.reducer
