import React from 'react'
import Button from '../../../Components/Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { useStyles } from '../ProofOfIncomeStyle'
import { Typography } from '@mui/material'
import { transformFileToBase64 } from '../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { setErrors, setFormData } from '../store'

const UploadFile = ({ onDelete }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputFile = React.useRef(null)
  const [touched, setTouched] = React.useState(false)
  const { formData, errors } = useSelector((state) => state.ccProofOfIncome)

  const handleOnchangeFile = (event) => {
    const file = event.target.files[0]
    if (file) {
      transformFileToBase64(file)
        .then((base64) => {
          dispatch(setFormData({ file: { base64: base64, name: file.name } }))
          dispatch(setErrors({ file: null }))
        })
        .catch((error) => {
          dispatch(setFormData({file: { base64: '', name: '' } }))
          dispatch(setErrors({ file: 'The file could not be uploaded' }))
        })
    }
  }

  const handleDelete = () => {
    dispatch(setFormData({ file: null }))
    if (onDelete) onDelete()
  }

  return (
    <React.Fragment>
      <Button
        Title='Please upload your job letter'
        variant='h7'
        className={classes.UploadFiles}
        endIcon={<AttachFileIcon />}
        onClick={() => {
          inputFile.current.click()
          setTouched(true)
        }}
      />

      <>
        <input
          // {...field}
          type='file'
          accept='application/pdf,image/*'
          onChange={(event) => {
            handleOnchangeFile(event)
          }}
          value=''
          style={{ display: 'none' }}
          ref={inputFile}
          // error={touched && errorFile}
        />
        <Typography className={classes.UploadFileText}>
          Only pdf documents are permitted with a maximum of 2MB
        </Typography>
        {errors?.file ? (
          <p className={classes.errorMessage}>{errors.file}</p>
        ) : (
          <React.Fragment>
            {formData.file?.name && (
              <p className={classes.uploadFileName}>
                {`${String(formData.file.name).substring(0, 20)}...`}
                <span>
                  <ClearIcon
                    className={classes.deleteFileIcon}
                    onClick={handleDelete}
                  />
                </span>
              </p>
            )}
          </React.Fragment>
        )}
      </>
    </React.Fragment>
  )
}

export default UploadFile
