import React from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material'
import { MasterAction } from './settings.js'
import { useStyles } from './CardCurrentApplicationStyle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { EmailPreviewLoading } from '../../../Pages/components/skeleton'
import CardCurrentApplication from '../../Components/CardCurrentApplication/CardCurrentApplication'

const CurrentApplication = () => {
  const classes = useStyles()

  const { currentData: applications, pending } = useSelector((state) => state.ccCurrentApplication)

  const extractAction = (application) => {
    if (application.step === 'change-schedule-appointment-cc')  return application.step

    return application.status.keyValue
  }

  const hasCustomPrimaryAction = (status) => {
    console.log('status', status)
    return Object.keys(MasterAction).includes(status)
  }

  return (
    <>
      <Stack spacing={{ xs: 4, md: 2 }} direction='column' mb={3} width='100%'>
        <Accordion expanded={true} elevation={0} className={classes.Accordian}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='body2' className={classes.AccordianHeader}>
              My Current Applications
            </Typography>
          </AccordionSummary>
          {pending.current ? (
            <EmailPreviewLoading />
          ) : applications?.length > 0 ? (
            <AccordionDetails
              style={{
                gap: '2rem',
                display: 'flex',
                flexDirection: 'column'
              }}
              sx={{ backgroundColor: '#FFFF', padding: 10 }}
              mt={3}
            >
              {applications.map((app) => (
                <CardCurrentApplication key={app.id} CurrentDetails={app}>
                  { hasCustomPrimaryAction(extractAction(app)) && MasterAction[extractAction(app)]({ application: app }) }
                </CardCurrentApplication>
              ))}
            </AccordionDetails>
          ) : (
            <Typography variant='body2' className={classes.AccordianHeader}>
              No current applications found.
            </Typography>
          )}
        </Accordion>
      </Stack>
    </>
  )
}

export default CurrentApplication
