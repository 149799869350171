import JwtServiceCreditCard from "./cc/jwtServiceCreditCard"

export default function UJwtCreditCard(jwtOverrideConfig) {
  const jwtCC = new JwtServiceCreditCard(jwtOverrideConfig)

  // console.log('UJwtCreditCard jwtCC', jwtCC)

  return {
    jwtCC
  }
}
