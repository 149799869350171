import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { authenticate } from './store'
import { endpoints } from '../../../core/jwt/cc/endpoints'
import Spinner from '../../../Components/BackDrop/Spinner'

const HomeCreditCardPage = () => {
  // http://localhost:3000/credit-card-application/?q=U2FsdGVkX1%2B
  const navigate = useNavigate()
  const location = useLocation()

  const query = location.search.split('?q=')[1]
  const dispatch = useDispatch()

  useEffect(() => {
    const sendData = {
      token: query
    }
    if (query) {
      dispatch(authenticate(sendData)).then((response) => {
        const token = response?.payload?.jwt
        sessionStorage.setItem(endpoints.tokenKeyName, token)
        if (token) {
          navigate('/select-options')
        }
      })
    }

  }, [query])


  return (
    <React.Fragment>
      <Spinner open={true}/>
    </React.Fragment>
  )
}

export default HomeCreditCardPage