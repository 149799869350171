import React, { useState } from 'react'
import { useStyles } from './CardWithButtonStyle'
import { Card, CardContent, Typography } from '@mui/material'
import FlechaBlanca from '../../../assets/Images/FlechaBlanca'
import Title from '../../../Components/AtomicDesign/atoms/Title/Title'

const CardWithButton = ({ card, handleActive }) => {
  const [onHoverCard, setonHoverCard] = useState(false)
  const classes = useStyles()

  return (
    <Card
      onClick={handleActive}
      onMouseEnter={() => setonHoverCard(true)}
      onMouseLeave={() => setonHoverCard(false)}
      sx={{
        backgroundImage:`linear-gradient(${card.gradient}), url(${card.bgImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius:'9.15px',
        transition: 'background-color 0.25s ease-in-out'
      }}
      classes={{ root: classes.mainCardStyle }}
    >
      <CardContent
        style={{
          height: '112px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          color: '#ffffff',
          justifyContent: 'space-evenly',
          cursor: 'pointer',
          gap:'24px'
        }}
      >
        <h2 className={classes.titleCard}>
          {card.PrincipalTitle}
        </h2>
        <Typography className={classes.btnVariation} style={{
           transition: 'background-color 0.25s ease-in-out',
           transform: onHoverCard ? 'scale(1.03)' :''
        }}>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            {card?.actionKey?.toUpperCase() || 'VIEW'} {'  '}
            <FlechaBlanca fill={'white'} />
          </span>
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CardWithButton
