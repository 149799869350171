import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import React from 'react'
import { useStyles } from './SignDocumentsStyle'
import CardSecureMessage from '../../Components/Cards/CardSecureMessage/CardSecureMessage'
// import CoapplicantForm from '../../Components/CoapplicantForm/CoapplicantForm'
import { useFormik } from 'formik'
import DatePicker from '../../Components/Inputs/DatePicker'
import TimePicker from '../../Components/Inputs/TimePickerAppointment'
import { getMinHours, minDate } from '../../../Utils/useMinDate'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSignBranches,
} from '../SignDocuments/store'
import { hoursInterval } from './options'
import { getRandomTime, validationSchema } from './settings'
import moment from 'moment-timezone'


const SignDocumentsOnSite = ({ data, showInfo = true, onSubmit }) => {
  const classes = useStyles()
  const { branches } = useSelector((state) => state.ccSignDocuments)

  const dispatch = useDispatch()

  const {
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
    setValues
  } = useFormik({
    initialValues: {
      branchId: '',
      appointmentDate: moment.tz('America/Belize').add(1, 'days').toDate(),
      appointmentTime: getRandomTime(),
      branchPickupId: ''
    },
    validationSchema,
    onSubmit: (values) => {
        onSubmit(values)
    }
  })

  React.useEffect(() => {
    dispatch(getSignBranches())
  }, [])

  React.useEffect(() => {
    if (data) {
      setValues({
        branchId: data.branchId || '',
        appointmentDate: data.appointmentDate ? moment.tz(data.appointmentDate, 'America/Belize').toDate(): moment.tz('America/Belize').add(1, 'days').toDate(),
        appointmentTime: data.appointmentTime
          ? moment.tz(data.appointmentTime, 'America/Belize').toISOString()
          : getRandomTime(),
        branchPickupId: data.branchPickupId || ''
      })
    }
  }, [data, setValues])

  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        {showInfo && (
          <CardSecureMessage className={classes.secureMessage}>
            <Typography>
              As your approved card is above BZ$7,000, please set up an
              appointment with the nearest branch in order to sign all necessary
              documents.
            </Typography>
          </CardSecureMessage>
        )}
        <form onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            <InputLabel className={classes.inputLabel}>
              Please select one of our locations for your co-applicant to visit
              to sign your documents
            </InputLabel>
            <FormControl
              fullWidth
              error={touched.branchId && Boolean(errors.branchId)}
            >
              <Select {...getFieldProps('branchId')} displayEmpty>
                {branches?.branches?.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.branchId && errors.branchId && (
                <p className={classes.errorMessage}>{errors.branchId}</p>
              )}
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <InputLabel className={classes.inputLabel}>
              Please select the date and time for your co-applicant to visit the
              branch
            </InputLabel>
            <div className={classes.dateTimeContainer}>
              <DatePicker
                Label='SELECT APPOINTMENT DATE'
                value={values.appointmentDate}
                openTo='year'
                views={['year', 'month', 'day']}
                style={{ backgroundColor: '#ffff' }}
                disablePast={true}
                onChange={(e) => setFieldValue('appointmentDate', e)}
                disableFuture={false}
                minDate={minDate('14:00:00Z')}
                name='appointmentDate'
                error={
                  touched.appointmentDate && Boolean(errors.appointmentDate)
                }
                helperText={touched.appointmentDate && errors.appointmentDate}
              />
              <TimePicker
                Label=' SELECT APPOINTMENT TIME'
                placeholder='8:00AM'
                value={values.appointmentTime}
                style={{ backgroundColor: '#ffff' }}
                name='appointmentTime'
                appointmentDate={values.appointmentDate}
                minTime={getMinHours(values.appointmentDate, hoursInterval)}
                onChange={(e) => setFieldValue('appointmentTime', e)}
                error={
                  touched.appointmentTime && Boolean(errors.appointmentTime)
                }
                helperText={touched.appointmentTime && errors.appointmentTime}
              />
            </div>
          </div>
          <div className={classes.inputContainer}>
            <InputLabel className={classes.inputLabel}>
              Please select one of our locations to pick up your card from
            </InputLabel>
            <FormControl
              fullWidth
              error={touched.branchPickupId && Boolean(errors.branchPickupId)}
            >
              <Select {...getFieldProps('branchPickupId')} displayEmpty>
                {branches?.branches?.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.branchPickupId && errors.branchPickupId && (
                <p className={classes.errorMessage}>{errors.branchPickupId}</p>
              )}
            </FormControl>
          </div>
          <div className={classes.buttonContainer}>
            <button className={classes.button} type='submit'>
              SCHEDULE
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignDocumentsOnSite
