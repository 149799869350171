import { makeStyles } from '@material-ui/core/styles'
import BgImage from '../../../assets/CCImages/bg-title-page.png'

export const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    minHeight:'100vh'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${BgImage})`,
    backgroundPosition: 'center',
    textAlign: 'center',
    padding: 20,
    height: '114px',
    borderRadius: '8px'
  },
  Header: {
    '&.MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '29.677px',
      fontFamily: theme.fonts.family.secondary,
      color: '#ffff'
    }
  },
  ContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    padding:'0px 28px'
    
  },
  SectionHeader: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '12px', 
    marginTop:'32px'
  },
  Title: {
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 600,
    fontSize: '22px'

  },
  Number: {
    padding: '4px 11px',
    borderRadius: '100%',
    background: '#0066A4',
    color: '#ffffff',
    fontWeight: '600',
    fontSize: '20px',
    fontFamily: theme.fonts.family.secondary
  },
  BtnNext: {
    backgroundColor: '#0066A4',
    color: '#fff',
    borderRadius: '2rem',
    padding: '7px 32px',
    minWidth: '165px',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #0066A4',
      color: '#0066A4'
    },
    '&.Mui-disabled': {
      backgroundColor: '#d3d3d3',
      color: '#a9a9a9'
    }
  },
  BtnBack: {
    backgroundColor: '#fff',
    border: '1px solid #0066A4',
    color: '#0066A4',
    borderRadius: '2rem',
    padding: '7px 32px',
    minWidth: '165px',
    '&:hover': {
      backgroundColor: '#0066A4',
      color: '#fff'
    }
  },
}))
