import { baseUrl } from '../../../baseUrl'

const creditCard = `${baseUrl}/credit-card`

export const endpoints = {
  authentication: `${creditCard}/auth`,
  creditCardEndpoint: `${creditCard}`,
  application: `${creditCard}/application`,
  creditCards: `${creditCard}/available-catalog`,
  cardDetailsById: `${creditCard}/available-catalog/comparation`,

  personalInformationReviewGET: `${creditCard}/personal-information-review`,
  pirCheckPhonePOST: `${creditCard}/personal-information-review/check-phone`,
  pirConfirmationPhonePOST: `${creditCard}/personal-information-review/confirmation/phone`,
  pirResendCodePhonePOST: `${creditCard}/personal-information-review/confirmation/resend-code/phone`,
  pirCheckEmailPOST: `${creditCard}/personal-information-review/check-email`,
  pirConfirmationEmailPOST: `${creditCard}/personal-information-review/confirmation/email`,
  pirResendCodeEmailPOST: `${creditCard}/personal-information-review/confirmation/resend-code/email`,
  pirUploadSSNPOST: `${creditCard}/customer-personal-document`,

  additionalApplicantsGET: `${creditCard}/additional-applicants`,
  applicantTypeCatalogGET: `${creditCard}/applicant-type-catalog`,
  districtsCatalogGET: `${creditCard}/districts`,
  urbanAreasCatalogGET: `${creditCard}/urban-areas`,
  relationshipsCatalogGET: `${creditCard}/relationships`,
  countriesCatalogGET: `${creditCard}/countries`,
  statesCatalogGET: `${creditCard}/states`,
  occupantsCatalogGET: `${creditCard}/occupants`,
  titlePersonCatalogGET: `${creditCard}/title-person`,
  additionalApplicantsPOST: `${creditCard}/additional-applicants`,
  additionalApplicantsPUT: `${creditCard}/additional-applicants`,

  limitRequest: `${creditCard}/limit-request`,
  promoCode: `${creditCard}/promo-code`,
  insurance: `${creditCard}/insurance-options`,
  selectedCardID: `${creditCard}/get-selected-card-id`,
  updateCredtiCardType: `${creditCard}/update-credit-card-type-customer`,
  monthlyIncome: `${creditCard}/monthly-income`,
  monthlyExpenses: `${creditCard}/monthly-expenses`,
  limitTypes: `${creditCard}/limit-increase/catalog/limit-type`,
  limitDurations: `${creditCard}/limit-increase/catalog/limit-temp-time`,
  userCreditCardsInfo: `${creditCard}/assigned`,

  applicationsHistoryGET: `${creditCard}/application/history`,
  resumeApplicationGET: `${creditCard}/resume-application`,
  signDocumentsGET: `${creditCard}/sign-documents`,
  signBranchesGET: `${baseUrl}/branches`,
  pickupBranchPOST: `${creditCard}/application/pickup-branch`,
  signingAppointmentPOST: `${creditCard}/signing-appointment`,

  //CC Standing Order
  standingOrder: `${creditCard}/standing-order`,


  // TODO: Add all this endpoints below here to nginx
  proofOfIncomeGET: `${creditCard}/proof-of-income`,
  salaryCatalogGET: `${creditCard}/proof-of-income/catalog/salary`,
  jobLetterCatalogGET: `${creditCard}/proof-of-income/catalog/job-letter`,
  // TODO: Change this externalBank to use the CreditCard Banks Catalog
  externalBankCatalogGET: `${baseUrl}/banks`,
  proofOfIncomePOST: `${creditCard}/proof-of-income`,
  proofOfIncomePUT: `${creditCard}/proof-of-income`,
  // separate
  // * documents required
  missingDocs: `${creditCard}/application/missing-docs`,
  applicationDocsGET: `${creditCard}/application-docs`,
  applicationDocsTypesListGET: `${creditCard}/application-docs/types-list`,
  applicationDocsPOST: `${creditCard}/application-docs`,
  applicationDocsDELETE: `${creditCard}/application-docs`,
  // separate
  customerHasSSBGET: `${creditCard}/customer-has-ssb`,
  adMissingDocsGET: `${creditCard}/application/missing-docs`,
  // separate
  adCheckRequirementsGET: `${creditCard}/application/check-requirements`,
  adAssignLenderGET: `${creditCard}/application/assign-lender`,
  adCompleteGET: `${creditCard}/application/complete`,

  tokenKeyName: 'access_token_credit_card',
  tokenType: 'Bearer',
  storageRefreshTokenKeyName: 'refreshToken_credit_card'
}
