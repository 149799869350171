import React from 'react'
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  createFilterOptions,
  InputLabel,
  Stack
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from '../ProofOfIncomeStyle'
import { setFormData } from '../store'
import clsx from 'clsx'

const InstitutionFinancial = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [touched, setTouched] = React.useState(false)
  const { catalogs, formData, errors } = useSelector((state) => state.ccProofOfIncome)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <InputLabel shrink className={classes.SelectLable}>
        Select a Financial Institution
      </InputLabel>
      <Autocomplete
        fullWidth
        disablePortal
        options={catalogs.bankCatalog}
        filterOptions={createFilterOptions({
          stringify: (option) => option.name
        })}
        getOptionLabel={(option) => option.name}
        value={catalogs.bankCatalog.find((item) => item.id === formData?.bankId) || null}
        onChange={(event, newValue) => {
          event.preventDefault()
          dispatch(setFormData({ bankId: newValue.id }))
        }}
        className={clsx(classes.Select)}
        classes={{ paper: classes.paper }}
        renderInput={(params) => (
          <TextField
            {...params}
            // name={field.name}
            placeholder='Please select one option'
          />
        )}
      />
      {errors?.bankId && (
        <p className={classes.errorMessage}>{errors.bankId}</p>
      )}
    </div>
  )
}

export default InstitutionFinancial
