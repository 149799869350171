// credit card pages repetaed because of the design changes
import CreditLimitIncreaseRequest from '../CreditCardPhaseOne/Pages/CreditLimitIncreaseRequest/CreditLimitIncreaseRequest'
import PersonalInformationReviewCC from '../CreditCardPhaseOne/Pages/PersonalInformationReviewForm/PersonalInformationReview'


const LimitIncreasePages = [
  {
    localeKey: 'Personal Information Review',
    key: '/personal-information-review',
    Component: PersonalInformationReviewCC,
    Protected: true
  },
  {
    localeKey: 'Credit Limit Increase Request',
    key: '/request',
    Component: CreditLimitIncreaseRequest,
    Protected: true
  },
]

export default LimitIncreasePages
