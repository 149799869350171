import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material'
import { useStyles } from './styles'
import ModalCreditCard from '../ModalCreditCard/ModalCreditCard'
import { useSelector } from 'react-redux'

const CreditCardInfo = ({ cardInfo, setSelectedCC, disabledCheck }) => {
  const classes = useStyles()

  console.log('CreditCardInfo -> cardInfo', cardInfo)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [learnMoreInfo, setLearnMoreInfo] = useState({})
  const { selectedCC } = useSelector((state) => state.availableCreditCard)

  const handleOnChange = (position, e) => {
    if (e.target.checked) setSelectedCC((initState) => [...initState, position])

    if (!e.target.checked)
      setSelectedCC((oldValues) => {
        return oldValues.filter((checked) => checked !== position)
      })
  }

  return (
    <>
      <Card style={{ padding: '3rem 2rem', marginBottom: '3rem' }}>
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: '5rem',
            flexWrap: 'wrap'
          }}
        >
          <div style={{ minWidth: '400px', maxWidth: '539px' }}>
            <Typography fontSize={24} fontWeight={700} color='#091242'>
              {cardInfo.title}
            </Typography>
            <ul>
              {cardInfo.bulletPoints.map((points) => (
                <li key={points.id}>
                  <Typography fontSize={14}>{points.text}</Typography>
                </li>
              ))}
            </ul>
            <hr />
            {cardInfo.benefits.map((benefit) => (
              <div
                key={benefit.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.7rem',
                  marginBottom: '1rem'
                }}
              >
                <img src={benefit.icon} alt='' />
                <Typography color='#868686' fontSize={14}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: benefit.text
                    }}
                  />
                  {/* {benefit.text} */}
                </Typography>
              </div>
            ))}
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                alignItems: 'center',
                gap: '3rem'
              }}
            >
              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#005FAA',
                  width: '255px',
                  borderRadius: '2rem',
                }}
                disabled={cardInfo.disabled}
                onClick={() => {
                  setLearnMoreInfo({
                    ...cardInfo,
                    cardType: cardInfo.cardType,
                    segment: cardInfo.segment,
                    id: cardInfo.id
                  })
                  setIsOpenModal(true)
                }}
              >
                Learn more +
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    // disabled={disabledCheck}
                    disabled={cardInfo.disabled}
                    onChange={(e) => handleOnChange(cardInfo.id, e)}
                    value={cardInfo.id}
                    checked={selectedCC.includes(cardInfo.id)}
                  />
                }
                label='Add to comparasion'
              />
            </div>
          </div>
          <img width={359} height={228} src={cardInfo.cardImage} alt='' />
        </CardContent>
      </Card>

      <ModalCreditCard
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        modalInfo={learnMoreInfo}
        setOpenModal={setIsOpenModal}
      />
    </>
  )
}

export default CreditCardInfo
