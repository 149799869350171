import { Card, CardContent } from '@mui/material';
import React from 'react';
import { useStyles } from './CardSecureMessageStyle';
import SecureIcon from "../../../../../src/assets/Images/secureIcon.png"
const CardSecureMessage = ({ styles, children }) => {
    const classes = useStyles();
    return (
        <Card style={{ display: "flex", alignItems:"center", ...styles }}>
            <div className={classes.greenLine}>
                {/* <FiberManualRecord /> */}
            </div>
            <img
              src={SecureIcon}
              alt=''
              style={{maxWidth:"50px", maxHeight:"50px", marginRight: "20px"}}
            />
            <CardContent>
                {children}
            </CardContent>
        </Card>
    );
};

export default CardSecureMessage;
