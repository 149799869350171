import React from 'react'
import { Alert, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './UploadDocumentsStyle'
import {
  checkApplicationStatus,
  getMissingDocs,
  ssnDocumentPending,
  adAssignLenderCall,
  adCompleteCall
} from './store'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import { deleteDocCall } from '../../StoreCC/upload-store'
import MissingDocuments from './containers/MissingDocuments'
import { updateApplicationStep } from '../../context/global-application'
import { APPLICATION_STEP } from '../../options'
import UploadSSN from './containers/UploadSSN'

const UploadDocumentsCC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { checkRequirements } = useSelector((state) => state.ccUploadDocuments)
  const navigate = useNavigate()

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const handleNext = () => {

    dispatch(checkApplicationStatus()).then((response) => {
      const processComplete = response?.payload?.hasCompletedRequirements

      if (processComplete) {
        dispatch(adAssignLenderCall()).then(() => {
          dispatch(adCompleteCall()).then(() => {
            navigate('/my-applications')
          })
        })
      }

    })
  }

  React.useEffect(() => {
    dispatch(updateApplicationStep(APPLICATION_STEP['upload-documents-cc'].key))
  }, [])

  React.useEffect(() => {
    dispatch(checkApplicationStatus()).then((response) => {
      const hasCompletedRequirements = response.payload.hasCompletedRequirements || false
      if (hasCompletedRequirements) {
        handleNext()
        // return navigate('/my-applications')
      }

      // dispatch(getMissingDocs())
      // dispatch(ssnDocumentPending())
    })
  }, [])

  return (
    <Box>
      <div className={classes.ButtonTitle}>Upload Documents</div>

      <Alert
        icon={false}
        sx={{
          backgroundColor: '#FFD6D6',
          padding: '20px',
          marginBottom: '24px'
        }}
      >
        <strong>Dear {localStorage.getItem('name') || 'Client'},</strong>
        <br />
        If you have any pending documents to upload prior to the final
        submission, please feel free to resume your application as soon as you
        have obtained those documents. Rest assured that all your information
        and uploaded documents have been saved.
      </Alert>

      <Typography className={classes.SelectLable}>
        Please complete the following
      </Typography>

      <Typography className={classes.errorMessage}>
        * Mandatory Upload
      </Typography>

      {!checkRequirements?.hasCompletedRequirements && (
        <React.Fragment>
          <UploadSSN />
          <MissingDocuments />
        </React.Fragment>
       )}

      <ArrowButton back={'/credit-card/proof-of-income'} onClick={handleNext} />
    </Box>
  )
}

export default UploadDocumentsCC
