import React from 'react'
import CustomButton from '../components/CustomButton'
import { useDispatch } from 'react-redux'
import ModalContainer from '../../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import SignDocumentsOnSite from '../../SignDocumentsOnSite/SignDocumentsOnSite'
import { getApplicationResume, getApplicationsComplete, getApplicationsCurrent, getPickupBranch, getScheduleAppointment, updatePickupBranch, updateScheduleAppointment } from '../store'

const ChangeScheduleAppointment = ({ application }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [temporalSchedule, setTemporalSchedule] = React.useState({
    branchId: '',
    appointmentDate: '',
    appointmentTime: '',
    branchPickupId: ''
  })

  console.log('application ChangeScheduleAppointment', application)

  const onClose = () => {
    setOpen(false)
  }

  const saveTemporalData = (data) => {
    if (!data) return

    setTemporalSchedule((prevState) => ({
      ...prevState,
      ...data
    }))
  }

  const getData = async () => {
    return [
      await dispatch(getScheduleAppointment()).then((response) => saveTemporalData(response.payload.principalDetails)),
      await dispatch(getPickupBranch()).then((response) => saveTemporalData({ branchPickupId: response.payload.id }))
    ]
  }

  React.useEffect(() => {
    dispatch(getApplicationResume(application.id)).finally(() => getData())
  }, [application])

  const handleOnSubmit = async (values) => {
    console.log('values', values)

    const sendDataSchedule = {
      branchId: values.branchId,
      appointmentDate: values.appointmentDate,
      appointmentTime: values.appointmentTime
    }

    const sendDataPickup = {
      branchId: values.branchPickupId
    }

    dispatch(updateScheduleAppointment(sendDataSchedule)).finally(() => {
      dispatch(updatePickupBranch(sendDataPickup)).finally(() => {
        dispatch(getApplicationsComplete())
        dispatch(getApplicationsCurrent())
        onClose()
      })
    })
  }


  return (
    <React.Fragment>
      <ModalContainer open={open} width={'auto'} onClose={onClose}>
        <SignDocumentsOnSite data={temporalSchedule} showInfo={false} onSubmit={handleOnSubmit}/>
      </ModalContainer>
      <CustomButton title='CHANGE SCHEDULE' onClick={() => setOpen(true)} />
    </React.Fragment>
  )
}

export default ChangeScheduleAppointment
