import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  InsuranceContainer: {
    display: 'flex',
    gap: '1.5rem',
    borderRadius: '8px',
    padding: '1rem',
    marginTop: '2rem',
    position: 'relative',
    cursor: 'pointer',
    '@media (max-width: 636px)': {
      gap: '1.2rem',
      padding: '25px 10px 10px 10px',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& img': {
      width: 272,
      objectFit: 'cover',
      borderRadius: 12,
      '@media (max-width: 636px)': {
        width: 212
      }
    }
  }
}))
