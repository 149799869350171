import React from 'react'
import SignDocumentsOnSite from './SignDocumentsOnSite'
import { useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { postPickupBranch, sendScheduledAppointment } from '../SignDocuments/store'
import { updateApplicationStep } from '../../context/global-application'
import { APPLICATION_STEP } from '../../options'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../Components/BackDrop/Spinner'


const SchedulePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pending, setPending] = React.useState(false)

  const onSubmitted = (data) => {
      const belizeDate = moment.tz(data.appointmentDate, 'America/Belize').toISOString()
      const belizeTime = moment.tz(data.appointmentTime, 'America/Belize').toISOString()

      const sendDataAppointment = {
        branchId: data.branchId,
        appointmentDate: belizeDate,
        appointmentTime: belizeTime
      }

      const sendDataPickup = { branchId: data.branchPickupId }
      setPending(true)
      dispatch(sendScheduledAppointment(sendDataAppointment)).finally(() => {
          dispatch(postPickupBranch(sendDataPickup)).finally(() => {
            setPending(false)
            dispatch(updateApplicationStep(APPLICATION_STEP['standing-order-cc'].key))
            navigate(APPLICATION_STEP['standing-order-cc'].path)
          })
      })
    }

  return (
    <div style={{ marginBottom: '10rem' }}>
      <Spinner open={pending} />
      <SignDocumentsOnSite onSubmit={onSubmitted}/>
    </div>
  )
}

export default SchedulePage