// credit card pages repetaed because of the design changes
import PersonalInformationReviewCC from '../CreditCardPhaseOne/Pages/PersonalInformationReviewForm/PersonalInformationReview'
import CreditLimitRequest from '../CreditCardPhaseOne/Pages/CreditLimitRequest/CreditLimitRequest'
import AddiontalApplicants from '../CreditCardPhaseOne/Pages/AdditionalApplicants/AddiontalApplicants'
import MonthlyIncomeCC from '../CreditCardPhaseOne/Pages/MonthlyIncome/MonthlyIncome'
import MonthlyExpensesCC from '../CreditCardPhaseOne/Pages/MonthlyExpenses/MonthlyExpenses'
import ProofOfIncomeCC from '../CreditCardPhaseOne/Pages/ProofOfIncome/ProofOfIncome'
import UploadDocumentsCC from '../CreditCardPhaseOne/Pages/UploadDocuments/UploadDocumets'
import ApprovedBelow7k from '../CreditCardPhaseOne/Components/SignDocuments/ApprovedBelow7k'
import ScheduleAppointment from '../CreditCardPhaseOne/Components/SignDocuments/SignDocuments'
import SignDocuments from '../CreditCardPhaseOne/Pages/SignDocuments/SignDoucuments'
import SignDocumentsOnSite from '../CreditCardPhaseOne/Pages/SignDocumentsOnSite/SignDocumentsOnSite'
import CreditCardStandingOrder from '../CreditCardPhaseOne/Pages/CreditCardStandingOrder/CreditCardStandingOrder'
import SchedulePage from '../CreditCardPhaseOne/Pages/SignDocumentsOnSite'

const CC_PAGES = [
  {
    localeKey: 'Personal Information Review',
    key: '/personal-information-review',
    Component: PersonalInformationReviewCC,
    Protected: true
  },
  {
    localeKey: 'Credit Limit Request',
    key: '/credit-limit-request',
    Component: CreditLimitRequest,
    Protected: true
  },
  {
    localeKey: 'Additional Applicants',
    key: '/additional-applicants',
    Component: AddiontalApplicants,
    Protected: true
  },
  {
    localeKey: 'Monthly Income',
    key: '/monthly-income',
    Component: MonthlyIncomeCC,
    Protected: true
  },
  {
    localeKey: 'Monthly Expenses',
    key: '/monthly-expenses',
    Component: MonthlyExpensesCC,
    Protected: true
  },
  {
    localeKey: 'Proof Of Income',
    key: '/proof-of-income',
    Component: ProofOfIncomeCC,
    Protected: true
  },
  {
    localeKey: 'Upload Documents',
    key: '/upload-documents',
    Component: UploadDocumentsCC,
    Protected: true
  },
  {
    localeKey: 'Approved',
    key: '/approved-below-7k',
    Component: ApprovedBelow7k,
    Protected: true
  },
  {
    localeKey: 'Sign Documents',
    key: '/sign-documents',
    Component: SignDocuments,
    Protected: true
  },
  {
    localeKey: 'Sign Documents',
    key: '/sign-documents-on-site',
    Component: SignDocumentsOnSite,
    Protected: true
  },
  {
    localeKey: 'Standing Order',
    key: '/standing-order',
    Component: CreditCardStandingOrder,
    Protected: true
  },
  {
    localeKey: 'Make Appointment Form',
    key: 'ScheduleAppointment/MakeAppointment', // ! cuando el cliente esta aprobado mayor a 7k y pendiente de cita
    Component: ScheduleAppointment,
    Protected: true
  },
  {
    localeKey: 'Credit Card Pending Signature',
    key: '/pending-signature-branch', // ! cuando el cliente esta aprobado mayor a 7k y pendiente de cita
    Component: SchedulePage,
    Protected: true
  }
]

export default CC_PAGES
