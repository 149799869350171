import { Box, Button, FormControlLabel, Checkbox } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'
import {
  TextField,
  MenuItem,
  FormControl,
  Autocomplete,
  createFilterOptions
} from '@mui/material'
import Select from '@mui/material/Select'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  addApplicantInfoCall,
  postAdditionalApplicantCall,
  updateAdditionalApplicantCall,
  fetchPrimaryCatalogs,
  statesCatalogCall,
  urbanAreasCatalogCall,
  getUserPreferenceForAdditionalApplicants
} from './store'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import InputMask from 'react-input-mask'
import { NumericFormat } from 'react-number-format'
import toast, { Toaster } from 'react-hot-toast'
import { APPLICATION_STEP } from '../../options'
import Spinner from '../../../Components/BackDrop/Spinner'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^501[67]\d{6}$/
const ssnRegex = /^\d{9}$/

function buildPayload(
  {
    applicantTypeID,
    titlePersonID,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    belizeBankAccountNumber,
    socialSecurityNumber,
    districtId,
    urbanAreaId,
    streetAddress,
    email,
    cellPhoneNumber,
    relationshipId,
    occupationID,
    employerName,
    employerCountryId,
    employerDistrictId,
    employerStreetAddress,
    monthlyIncome,
    assignedSupplementaryCardHolderAmount
  },
  id
) {
  const payload = {
    applicantDetails: {
      applicantTypeID,
      titlePersonID,
      firstName,
      lastName,
      dateOfBirth,
      socialSecurityNumber: socialSecurityNumber.replace(/\D/g, ''),
      districtId,
      urbanAreaId,
      streetAddress,
      email,
      cellPhoneNumber: cellPhoneNumber.replace(/\D/g, '').slice(3),
      relationshipId,
      middleName: middleName || '',
      belizeBankAccountNumber: belizeBankAccountNumber.replace(/[^a-zA-Z0-9]/g, '') || ''
    },
    employmentDetails: {
      occupationID,
      employerName,
      employerCountryId,
      employerStateId: employerDistrictId,
      employerStreetAddress,
      monthlyIncome
    }
  }
  // if (middleName && middleName.length > 0) {
  //   payload.applicantDetails.middleName = middleName
  // }
  // const cleanedBBAN = belizeBankAccountNumber.replace(/[^a-zA-Z0-9]/g, '') || ''
  // if (cleanedBBAN && cleanedBBAN.length > 0) {
  //   payload.applicantDetails.belizeBankAccountNumber = cleanedBBAN
  // }
  if (id) {
    payload.additionalApplicantID = id
  }

  if (payload.applicantDetails.applicantTypeID === 2) {
    payload.applicantDetails.assignedSupplementaryCardHolderAmount = assignedSupplementaryCardHolderAmount
  }

  return payload
}

const AddiontalApplicants = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    data,
    myRequestLimit,
    pendingDecision,
    additionalApplicantId,
    pending,
    error,
    updateAdditionalsApplicants
  } = useSelector((state) => state.additionalApplicants)

  const [canDisableBtn, setCanDisableBtn] = useState(false)
  const [visualIncome, setVisualIncome] = useState(null)
  const [visualIncomeCard, setVisualIncomeCard] = useState(null)
  const [cardHolderAmountInput, setCardHolderAmountInput] = useState(false)
  const [afterSendErors, setAfterSendErors] = useState({
    email: {
      match: null,
      isActive: false,
      message:
        'Email address matches the one of the primary applicant. Please enter a valid email address for co-applicant'
    },
    phone: {
      match: null,
      isActive: false,
      message:
        'Phone number matches the one of the primary applicant. Please enter a valid cell phone number for co-applicant'
    },
    bankAccount: {
      match: null,
      isActive: false,
      message: 'Belize Bank Account Number is required now'
    }
  })

  const [visualOccupantSelection, setVisualOccupantSelection] = useState(null)
  const filterOccupantsOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.occupationName
  })

  const {
    applicantsTypeCatalog,
    titlePersonCatalog,
    relationshipsCatalog,
    countriesCatalog,
    districtsCatalog,
    urbanAreasCatalog,
    statesCatalog,
    occupantsCatalog
  } = useSelector((state) => state.additionalApplicants.catalogs)

  const formik = useFormik({
    initialValues: {
      applicantTypeID: 0,
      titlePersonID: '',
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: null,
      belizeBankAccountNumber: '',
      socialSecurityNumber: '',
      districtId: '',
      urbanAreaId: '',
      streetAddress: '',
      email: '',
      cellPhoneNumber: '',
      occupationID: '',
      employerName: '',
      employerCountryId: '',
      employerDistrictId: '',
      employerStreetAddress: '',
      monthlyIncome: null,
      assignedSupplementaryCardHolderAmount: 0,
      relationshipId: ''
    },
    validationSchema: yup.object({
      applicantTypeID: yup
        .number()
        .required('This field is required')
        .min(1, 'This field is required, select an option'),
      dateOfBirth: yup
        .date()
        .nullable()
        .required('This field is required')
        .max(new Date(), 'The date of birth must be a valid date')
        .min(new Date('1/1/1900'), 'The date of birth must be a valid date'),
      monthlyIncome: yup
        .number()
        .nullable()
        .required('This field is required')
        .min(1, 'The monthly income value is not a valid'),
      titlePersonID: yup.number().required('This field is required'),
      districtId: yup.number().required('This field is required'),
      urbanAreaId: yup.number().required('This field is required'),
      occupationID: yup.number().required('This field is required'),
      employerCountryId: yup.number().required('This field is required'),
      employerDistrictId: yup.number().required('This field is required'),
      relationshipId: yup.number().required('This field is required'),
      assignedSupplementaryCardHolderAmount: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .when('applicantTypeID', {
          is: 2,
          then: yup
            .number()
            .required('Assign the supplementary cardholder amount is required')
            .min(500, 'The amount must be valid')
            .max(myRequestLimit?.requestedLimit, 'The amount must be less than the limit')
        }),
    }),
    validate: (toValidate) => {
      const newErrors = {}

      const simpleTexts = [
        'firstName',
        'lastName',
        'streetAddress',
        'employerName',
        'employerStreetAddress'
      ]

      simpleTexts.forEach((fieldName) => {
        if (!toValidate[fieldName] || toValidate[fieldName].trim() === '') {
          newErrors[fieldName] = 'This field is required'
        }
      })

      const cleanedSSN = toValidate.socialSecurityNumber.replace(/\D/g, '')
      if (
        !toValidate.socialSecurityNumber ||
        !cleanedSSN ||
        cleanedSSN.trim() === ''
      ) {
        newErrors.socialSecurityNumber = 'This field is required'
      } else if (!ssnRegex.test(cleanedSSN)) {
        newErrors.socialSecurityNumber =
          'The social security number must be valid'
      }

      if (!toValidate.email || toValidate.email.trim() === '') {
        newErrors.email = 'This field is required'
      } else if (!emailRegex.test(toValidate.email)) {
        newErrors.email = 'The email must be valid'
      }

      const cleanedPhoneNumber = toValidate.cellPhoneNumber.replace(/\D/g, '')
      if (
        !toValidate.cellPhoneNumber ||
        !cleanedPhoneNumber ||
        cleanedPhoneNumber.trim() === '501'
      ) {
        newErrors.cellPhoneNumber = 'This field is required'
      } else if (!phoneRegex.test(cleanedPhoneNumber)) {
        newErrors.cellPhoneNumber = 'The phone number must be valid'
      }

      const cleanedBankAccount =
        toValidate.belizeBankAccountNumber.replace(/[^a-zA-Z0-9]/g, '') || ''
      // 9 or 15
      if (cleanedBankAccount.length !== 0 && cleanedBankAccount.length < 9) {
        newErrors.belizeBankAccountNumber =
          'The Belize Bank Account Number must be valid'
      }

      return newErrors
    },
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const { errors, touched, getFieldProps } = formik

  useEffect(() => {
    window.scrollTo(0, 0)

    dispatch(getUserPreferenceForAdditionalApplicants()).then((response) => {

      if (response && response.payload && response.payload.rejectCoApplicant) return navigate(APPLICATION_STEP['credit-limit-request-cc'].path)


      dispatch(fetchPrimaryCatalogs()).then(() => {
        dispatch(addApplicantInfoCall()).then(() => {
          formik.validateForm()
        })
      })


    })
  }, [])

  useEffect(() => {
    if (data && data.applicantDetails) {
      formik.setValues(
        {
          applicantTypeID: data?.applicantType?.applicantTypeID || 0,
          titlePersonID: data?.title?.titlePersonID || '',
          firstName: data?.applicantDetails?.firstName || '',
          middleName: data?.applicantDetails?.middleName || '',
          lastName: data?.applicantDetails?.lastName || '',
          dateOfBirth: data?.applicantDetails?.dateOfBirth || null,
          belizeBankAccountNumber:
            data?.applicantDetails?.belizeBankAccountNumber || '',
          socialSecurityNumber:
            data?.applicantDetails?.socialSecurityNumber || '',
          districtId: data?.applicantDetails?.districtId || '',
          urbanAreaId: data?.applicantDetails?.urbanAreaId || '',
          streetAddress: data?.applicantDetails?.streetAddress || '',
          email: data?.applicantDetails?.email || '',
          cellPhoneNumber: data?.applicantDetails?.cellPhoneNumber
            ? '501' + data?.applicantDetails?.cellPhoneNumber
            : '',
          occupationID: data?.occupation?.occupationID || '',
          employerName: data?.employmentDetails?.employerName || '',
          employerCountryId: data?.employmentDetails?.employerCountryId || '',
          employerDistrictId: data?.employmentDetails?.employerStateId || '',
          employerStreetAddress:
            data?.employmentDetails?.employerStreetAddress || '',
          monthlyIncome: data?.employmentDetails?.monthlyIncome || null,
          relationshipId: data?.relationship?.relationshipID || '',
          assignedSupplementaryCardHolderAmount: data?.applicantDetails?.assignedSupplementaryCardHolderAmount || 0
        },
        true
      )

      setVisualIncome(data?.employmentDetails?.monthlyIncome || null)
      setVisualOccupantSelection(data?.occupation || null)
      setVisualIncomeCard(data?.applicantDetails?.assignedSupplementaryCardHolderAmount)
      // formik.validateField('cityId')
      formik.validateField('employerDistrictId')
    }
  }, [data])

  useEffect(() => {
    if (formik.values.districtId && !errors.districtId) {
      dispatch(urbanAreasCatalogCall(formik.values.districtId)).then(() => {
        formik.setFieldValue('cityId', data?.applicantDetails?.urbanAreaId || '')
      })
    }
  }, [formik.values.districtId, errors.districtId])

  useEffect(() => {
    if (formik.values.employerCountryId && !errors.employerCountryId) {
      dispatch(statesCatalogCall(formik.values.employerCountryId)).then(() => {
        formik.setFieldValue(
          'employerDistrictId',
          data?.employmentDetails?.employerStateId || ''
        )
      })
    }
  }, [formik.values.employerCountryId, errors.employerCountryId])

  useEffect(() => {
    if (error && error.message) {
      if (
        formik.values.email.length >= 5 &&
        error.message.includes(formik.values.email)
      ) {
        const newAfterError = { ...afterSendErors }
        newAfterError.email.match = formik.values.email
        newAfterError.email.isActive = true
        setAfterSendErors(newAfterError)
        toast.error(newAfterError.email.message)
        formik.setFieldError('email', newAfterError.email.message)
      }
      if (
        formik.values.cellPhoneNumber.length >= 5 &&
        error.message.includes(formik.values.cellPhoneNumber)
      ) {
        const newAfterError = { ...afterSendErors }
        newAfterError.phone.match = formik.values.cellPhoneNumber
        newAfterError.phone.isActive = true
        setAfterSendErors(newAfterError)
        toast.error(newAfterError.phone.message)
        formik.setFieldError('cellPhoneNumber', newAfterError.phone.message)
      }
    }
  }, [error])

  const handleSubmit = (values) => {
    if (!formik.isValid) {
      return
    }

    if (updateAdditionalsApplicants && additionalApplicantId) {
      dispatch(
        updateAdditionalApplicantCall(
          buildPayload(values, additionalApplicantId)
        )
      ).then((response) => {
        if (
          response &&
          response.payload &&
          response.payload.employmentDetails
        ) {
          navigate('/credit-card/monthly-income')
        }
      })
    } else {
      dispatch(postAdditionalApplicantCall(buildPayload(values))).then(
        (response) => {
          if (
            response &&
            response.payload &&
            response.payload.employmentDetails
          ) {
            navigate('/credit-card/monthly-income')
          }
        }
      )
    }
  }

  console.log('errors', errors)

  return (
    <Box>
      <Spinner open={pendingDecision} />
      {!pendingDecision && (
        <div className={classes.Container}>
          <div className={classes.ButtonTitle}>Supplementary Cardholders</div>

          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (!canDisableBtn) setCanDisableBtn(true)
              if (!formik.isValid) {
                window.scrollTo(0, 332)
                toast.error('Complete all the required fields')
              }
              formik.handleSubmit()
            }}
            className={classes.formAddAppStyle}
          >
            <Typography style={{ marginBottom: '1.6rem' }}>
              Please choose one of the following options:
            </Typography>

            <div className={classes.GridTemplate} style={{ marginLeft: 20 }}>
              {applicantsTypeCatalog.map((item, indx) => (
                <FormControlLabel
                  key={indx}
                  label={item.typeName}
                  control={
                    <Checkbox
                      onChange={() => {
                        console.log(item.applicantTypeID)
                          if (item.applicantTypeID === 2) {
                            setCardHolderAmountInput(true)
                          } else {
                            setCardHolderAmountInput(false)
                          }
                        formik.setFieldValue(
                          'applicantTypeID',
                          item.applicantTypeID
                        )
                      }}
                      checked={
                        formik.values.applicantTypeID === item.applicantTypeID
                      }
                      color='default'
                      sx={{
                        color: '#0066A4',
                        '&.Mui-checked': {
                          color: '#0066A4'
                        }
                      }}
                    />
                  }
                />
                
              ))}
              {touched.applicantTypeID && errors.applicantTypeID && (
                <p className={classes.errorMessage}>{errors.applicantTypeID}</p>
              )}
            </div>
            <br/>

                {(cardHolderAmountInput === true || formik.values.applicantTypeID === 2) &&
                <>
                  <label className={classes.FieldLabel}>
                  Assign the supplementary cardholder ammount
                </label>
                <NumericFormat
                  className={classes.NumericFieldStyle}
                  placeholder='BZ $ 0,000.00'
                  prefix={'BZ $ '}
                  decimalScale={2}
                  fixedDecimalScale={touched.monthlyIncome}
                  allowLeadingZero={false}
                  allowNegative={false}
                  thousandSeparator=','
                  {...getFieldProps('assignedSupplementaryCardHolderAmount')}
                  value={visualIncomeCard}
                  onChange={(e) => {
                    e.preventDefault()
                    setVisualIncomeCard(e?.target?.value)
                  }}
                  onValueChange={(values) => {
                    formik.setFieldValue(
                      'assignedSupplementaryCardHolderAmount',
                      values.floatValue !== undefined ? values.floatValue : 0
                    )
                  }}
                />
                </>}
            <p
              className={classes.errorMessage}
              style={{ margin: '8px 0 1.9rem 25px' }}
            >
              {touched.assignedSupplementaryCardHolderAmount && errors.assignedSupplementaryCardHolderAmount
                ? errors.assignedSupplementaryCardHolderAmount
                : ''}
            </p>

            <Typography
              style={{ fontSize: '24px', marginBottom: '2rem', color: '#0066A4' }}
            >
              Supplementary Cardholder Details
            </Typography>

            <div className={classes.GridTemplate}>
              <div>
                <label className={classes.FieldLabel}>Title</label>
                <Select
                  className={classes.SelectAddAppStyle}
                  {...getFieldProps('titlePersonID')}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>
                    Select your additional applicant’s title
                  </MenuItem>
                  {titlePersonCatalog.map((item, indx) => (
                    <MenuItem value={item.titlePersonID} key={indx}>
                      {item.titleName}
                    </MenuItem>
                  ))}
                </Select>
                {touched.titlePersonID && errors.titlePersonID && (
                  <p className={classes.errorMessage}>{errors.titlePersonID}</p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>First Name</label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='Enter your additional applicant’s first name'
                  fullWidth
                  {...getFieldProps('firstName')}
                />
                {touched.firstName && errors.firstName && (
                  <p className={classes.errorMessage}>{errors.firstName}</p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>
                  Middle Name (optional)
                </label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='Select your additional applicant’s middle name'
                  fullWidth
                  {...getFieldProps('middleName')}
                />
              </div>

              <div>
                <label className={classes.FieldLabel}>Last Name</label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='Enter your additional applicant’s last name'
                  fullWidth
                  {...getFieldProps('lastName')}
                />
                {touched.lastName && errors.lastName && (
                  <p className={classes.errorMessage}>{errors.lastName}</p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>Date of Birth</label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    openTo='year'
                    views={['year', 'month', 'day']}
                    disableFuture={true}
                    {...getFieldProps('dateOfBirth')}
                    onChange={(value) => {
                      if (!touched.dateOfBirth && value)
                        formik.setFieldTouched('dateOfBirth')

                      if (value && value.isValid()) {
                        formik.setFieldValue('dateOfBirth', value.toString())
                      } else {
                        formik.setFieldValue('dateOfBirth', null)
                      }

                      formik.validateField('dateOfBirth')
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={getFieldProps('dateOfBirth').name}
                        placeholder='MM/DD/YYYY'
                        fullWidth
                        sx={{
                          '& .MuiInputBase-input': {
                            fontSize: '0.9rem !important',
                            padding: '0.7rem 1rem !important',
                            marginTop: 0
                          },
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          '& .MuiInputLabel-formControl': {
                            display: 'none'
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {touched.dateOfBirth && errors.dateOfBirth && (
                  <p className={classes.errorMessage}>{errors.dateOfBirth}</p>
                )}
              </div>

              <div>
                <label
                  className={`${classes.FieldLabel} ${
                    afterSendErors.bankAccount.isActive ? classes.RedLabel : ''
                  }`}
                >
                  Belize Bank Account Number
                </label>
                <InputMask
                  mask='999999999999999'
                  alwaysShowMask={false}
                  {...getFieldProps('belizeBankAccountNumber')}
                >
                  {(inputProps) => (
                    <TextField
                      placeholder='000000000000000'
                      {...inputProps}
                      className={classes.TextFieldWithSpacingStyle}
                      fullWidth
                    />
                  )}
                </InputMask>
                {touched.belizeBankAccountNumber &&
                  errors.belizeBankAccountNumber && (
                    <p className={classes.errorMessage}>
                      {errors.belizeBankAccountNumber}
                    </p>
                  )}
              </div>

              <div>
                <label className={classes.FieldLabel}>
                  Social Security Number
                </label>
                <InputMask
                  mask='999 999 999'
                  alwaysShowMask={false}
                  {...getFieldProps('socialSecurityNumber')}
                >
                  {(inputProps) => (
                    <TextField
                      placeholder='000 000 000'
                      {...inputProps}
                      className={classes.TextFieldStyles}
                      fullWidth
                    />
                  )}
                </InputMask>
                {touched.socialSecurityNumber && errors.socialSecurityNumber && (
                  <p className={classes.errorMessage}>
                    {errors.socialSecurityNumber}
                  </p>
                )}
                {/* <Typography color='#FF0606' fontSize={12}>
                  ID has not been verified. Our representative will require
                  additional information when this application is submitted.
                </Typography> */}
              </div>

              <div>
                <label className={classes.FieldLabel}>District or state</label>
                <Select
                  className={classes.SelectAddAppStyle}
                  {...getFieldProps('districtId')}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>
                    Select a district or state
                  </MenuItem>
                  {districtsCatalog.map((district, indx) => (
                    <MenuItem value={district.id} key={indx}>
                      {district.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.districtId && errors.districtId && (
                  <p className={classes.errorMessage}>{errors.districtId}</p>
                )}
              </div>

              <div>
                <FormControl fullWidth disabled={!!errors.districtId}>
                  <label className={classes.FieldLabel}>
                    City, town or village
                  </label>
                  <Select
                    className={classes.SelectAddAppStyle}
                    {...getFieldProps('urbanAreaId')}
                    displayEmpty
                  >
                    <MenuItem value={''} disabled>
                      {errors.districtId
                        ? 'Please select a district or state'
                        : 'Select a city'}
                    </MenuItem>
                    {urbanAreasCatalog.map((city, indx) => (
                      <MenuItem value={city.id} key={indx}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.cityId && errors.cityId && (
                  <p className={classes.errorMessage}>{errors.cityId}</p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>
                  Street name and number
                </label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='Enter street name and number'
                  fullWidth
                  {...getFieldProps('streetAddress')}
                />
                {touched.streetAddress && errors.streetAddress && (
                  <p className={classes.errorMessage}>{errors.streetAddress}</p>
                )}
              </div>

              <div>
                <label
                  className={`${classes.FieldLabel} ${
                    afterSendErors.email.isActive ? classes.RedLabel : ''
                  }`}
                >
                  Email
                </label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='example@example.com'
                  fullWidth
                  {...getFieldProps('email')}
                />
                {touched.email && errors.email && (
                  <p className={classes.errorMessage}>{errors.email}</p>
                )}
                <Typography
                  style={{
                    color: '#0066A4',
                    fontSize: '14px',
                    fontWeight: '500',
                    margin: '1rem 0'
                  }}
                >
                  To complete your request to add a supplementary cardholder, the
                  email address provided will require validation. A validation
                  code will be sent shortly.
                </Typography>
              </div>

              <div>
                <label
                  className={`${classes.FieldLabel} ${
                    afterSendErors.phone.isActive ? classes.RedLabel : ''
                  }`}
                >
                  Cell Phone Number
                </label>
                <InputMask
                  mask='501-999-9999'
                  alwaysShowMask={false}
                  {...getFieldProps('cellPhoneNumber')}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      placeholder='501-000-0000'
                      type='tel'
                      className={classes.TextFieldStyles}
                      fullWidth
                    />
                  )}
                </InputMask>
                {touched.cellPhoneNumber && errors.cellPhoneNumber && (
                  <p className={classes.errorMessage}>{errors.cellPhoneNumber}</p>
                )}
                <Typography
                  style={{
                    color: '#0066A4',
                    fontSize: '14px',
                    fontWeight: '500',
                    margin: '1rem 0'
                  }}
                >
                  To complete your request to add a supplementary cardholder, the
                  cell phone number provided will require validation. A validation
                  code will be sent shortly.
                </Typography>
              </div>
            </div>

            <hr style={{ margin: '2rem 0' }} />
            <Typography
              style={{ fontSize: '24px', marginBottom: '2rem', color: '#0066A4' }}
            >
              Supplementary Cardholder - Employment Information
            </Typography>

            <div className={classes.GridTemplate}>
              <div>
                <label className={classes.FieldLabel}>Occupation</label>
                <Autocomplete
                  disablePortal
                  options={occupantsCatalog}
                  filterOptions={filterOccupantsOptions}
                  getOptionLabel={(option) => option.occupationName}
                  {...getFieldProps('occupationID')}
                  value={visualOccupantSelection}
                  onChange={(event, newValue) => {
                    event.preventDefault()
                    setVisualOccupantSelection(newValue)
                    formik.setFieldValue(
                      'occupationID',
                      newValue?.occupationID || ''
                    )
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '1px !important'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={getFieldProps('occupationID').name}
                      placeholder='Select your additional applicant’s occupation'
                      className={classes.TextFieldStyles}
                      fullWidth
                    />
                  )}
                />
                {touched.occupationID && errors.occupationID && (
                  <p className={classes.errorMessage}>{errors.occupationID}</p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>Name of Employer</label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='Name of Employer'
                  fullWidth
                  {...getFieldProps('employerName')}
                />
                {touched.employerName && errors.employerName && (
                  <p className={classes.errorMessage}>{errors.employerName}</p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>Employer’s country</label>
                <Select
                  className={classes.SelectAddAppStyle}
                  {...getFieldProps('employerCountryId')}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>
                    Selects applicant’s employer’s country
                  </MenuItem>
                  {countriesCatalog.map((country, indx) => (
                    <MenuItem value={country.id} key={indx}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.employerCountryId && errors.employerCountryId && (
                  <p className={classes.errorMessage}>
                    {errors.employerCountryId}
                  </p>
                )}
              </div>

              <div>
                <FormControl fullWidth disabled={!!errors.employerCountryId}>
                  <label className={classes.FieldLabel}>
                    Employer’s district or state
                  </label>
                  <Select
                    className={classes.SelectAddAppStyle}
                    {...getFieldProps('employerDistrictId')}
                    displayEmpty
                  >
                    <MenuItem value={''} disabled>
                      {errors.employerCountryId
                        ? 'Please select a Employer’s country'
                        : 'Select applicant’s employer’s district or state'}
                    </MenuItem>
                    {statesCatalog.map((state, indx) => (
                      <MenuItem value={state.id} key={indx}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.employerDistrictId && errors.employerDistrictId && (
                  <p className={classes.errorMessage}>
                    {errors.employerDistrictId}
                  </p>
                )}
              </div>

              <div>
                <label className={classes.FieldLabel}>
                  Employer’s street name and number
                </label>
                <TextField
                  className={classes.TextFieldStyles}
                  placeholder='Enter applicant’s employer’s street name and number'
                  fullWidth
                  {...getFieldProps('employerStreetAddress')}
                />
                {touched.employerStreetAddress &&
                  errors.employerStreetAddress && (
                    <p className={classes.errorMessage}>
                      {errors.employerStreetAddress}
                    </p>
                  )}
              </div>

              <div>
                <label className={classes.FieldLabel}>
                  Monthly Income from Employment
                </label>
                <NumericFormat
                  className={classes.NumericFieldStyle}
                  placeholder='BZ $ 0,000.00'
                  prefix={'BZ $ '}
                  decimalScale={2}
                  fixedDecimalScale={touched.monthlyIncome}
                  allowLeadingZero={false}
                  allowNegative={false}
                  thousandSeparator=','
                  {...getFieldProps('monthlyIncome')}
                  value={visualIncome}
                  onChange={(e) => {
                    e.preventDefault()
                    setVisualIncome(e?.target?.value)
                  }}
                  onValueChange={(values) => {
                    formik.setFieldValue(
                      'monthlyIncome',
                      values.floatValue ? values.floatValue : null
                    )
                  }}
                />
                {touched.monthlyIncome && errors.monthlyIncome && (
                  <p className={classes.errorMessage}>{errors.monthlyIncome}</p>
                )}
              </div>
            </div>

            <hr style={{ margin: '3rem 0' }} />
            <Typography
              style={{ fontSize: '24px', marginBottom: '2rem', color: '#0066A4' }}
            >
              Relationship with primary applicant
            </Typography>

            <div className={classes.GridTemplate}>
              <div>
                <label className={classes.FieldLabel}>
                  Relationship to the Primary Applicant
                </label>
                <Select
                  className={classes.SelectAddAppStyle}
                  {...getFieldProps('relationshipId')}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>
                    Select
                  </MenuItem>
                  {relationshipsCatalog.map((item, indx) => (
                    <MenuItem value={item.relationshipID} key={indx}>
                      {item.relationshipName}
                    </MenuItem>
                  ))}
                </Select>
                {touched.relationshipId && errors.relationshipId && (
                  <p className={classes.errorMessage}>{errors.relationshipId}</p>
                )}
              </div>
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                type='submit'
                className={classes.BtnConfirm}
                disabled={(canDisableBtn && !formik.isValid) || pending}
              >
                {pending ? '...' : 'Proceed'}
              </Button>
            </div>
          </form>
        </div>
      )}
      <Toaster />
    </Box>
  )
}

export default AddiontalApplicants
