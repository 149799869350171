import React, { useState } from 'react'
import { Button, CardContent, Typography } from '@mui/material'
import ModalContainer from '../../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import { useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import RequestAAdvantageModal from '../../../Components/Modals/RequestAAdvantageModal'
import { startNewApplication } from '../CreditCardComparison/store'
import { APPLICATION_STEP } from '../../../options'

const STEP_A_ADVANTAGE = {
  cardDetails: 'cardDetails',
  hasAAdvantage: 'hasAAdvantage',
  aAdvantageNumber: 'aAdvantageNumber',
  skipAAdvantage: 'skipAAdvantage'
}

const ModalCreditCard = ({ isOpen, onClose, modalInfo, setOpenModal }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const [isSelectedCard, setIsSelectedCard] = useState(false)
  const [step, setStep] = React.useState(STEP_A_ADVANTAGE.cardDetails)

  const handleSelectedCard = (card) => {
    if (modalInfo.requiredAA) return setStep(STEP_A_ADVANTAGE.hasAAdvantage)
    handleStartApplication(card)
  }

  const handleStartApplication = (card = null) => {

    const sendData = {
      selectedCardId: card.id,
      aadvantageNumber: card.aAdvantage
    }

    if (!card.aAdvantage) delete sendData.aadvantageNumber

    dispatch(startNewApplication(sendData)).then((response) => {
      console.log('response', response)
      navigate(APPLICATION_STEP['personal-information-review'].path)
    })
    // console.log('sendData', sendData)
  }


  return (
    <ModalContainer open={isOpen} onClose={onClose} width={'100%'}>
      {[step].includes(STEP_A_ADVANTAGE.cardDetails) && (
        <div style={{ padding: '3rem', overflowY: 'auto', height: '600px' }}>
          {modalInfo.segment && (
            <CardContent
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                gap: '5rem',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ minWidth: '400px', maxWidth: '539px' }}>
                <Typography fontSize={24} fontWeight={700} color='#091242'>
                  {modalInfo.title}
                </Typography>
                <ul>
                  {modalInfo.bulletPoints.map((points) => (
                    <li key={points.id}>
                      <Typography fontSize={14}>{points.text}</Typography>
                    </li>
                  ))}
                </ul>
                <hr />
                {modalInfo.benefits.map((benefit) => (
                  <div
                    key={benefit.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.7rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <img src={benefit.icon} alt='' />
                    <Typography color='#868686' fontSize={14}>
                      {/* {benefit.text} */}
                      <div dangerouslySetInnerHTML={{ __html: benefit.text }} />
                    </Typography>
                  </div>
                ))}
              </div>

              <div style={{ marginTop: '2rem' }}>
                <img
                  width={359}
                  height={228}
                  src={modalInfo.cardImage}
                  alt=''
                />
                <div
                  style={{
                    marginTop: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3rem'
                  }}
                >
                  <Button
                    style={{
                      // width: '255px',
                      backgroundColor: '#005FAA',
                      borderRadius: '2rem'
                    }}
                    variant='contained'
                    fullWidth
                    onClick={() => handleSelectedCard(modalInfo)}
                  >
                    Select This Card
                  </Button>
                </div>
              </div>
            </CardContent>
          )}
        </div>
      )}

      <RequestAAdvantageModal
        openModal={[step].includes(STEP_A_ADVANTAGE.hasAAdvantage)}
        setOpenModal={(isOpen) => {
          setStep(isOpen)
          setStep(STEP_A_ADVANTAGE.cardDetails)
        }}
        startApplication={(aAdvantageValue) => handleStartApplication({ ...modalInfo, aAdvantage: aAdvantageValue })}
      />

    </ModalContainer>
  )
}

export default ModalCreditCard
