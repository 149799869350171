export const CUSTOM_LINKS = {
  newApplication: {
    CreditCard: '/available-credit-cards',
    OLA: '/available-credit-cards',
  },
  offer: {
    CreditCard: '',
    OLA: '',
  },
  'limit-increase': {
    CreditCard: '/limit-increase/personal-information-review',
    OLA: '/limit-increase/request',
  },
}