import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography
} from '@mui/material'
import {
  getDebitAccountsList,
  getPaymentCommencesList,
  getPaymentTypesList,
  postStandingOrder,
  rejectStandingOrder
} from './store/index.js'
import bgImage from '../../../assets/CCImages/bg-title-page.png'
import RejectedModal from './RejectedModal.js'

const CreditCardStandingOrder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showRejected, setShowRejected] = React.useState(false)
  const [selectedAccount, setSelectedAccount] = useState('')
  const [paymentOption, setPaymentOption] = useState('') // Track selected payment option
  const [paymentDate, setPaymentDate] = useState('') // Track selected payment date

  // Access Redux state
  const { debitAccounts, paymentTypesList, paymentCommencesList } = useSelector((state) => state.ccStandingOrder)

  useEffect(() => {
    dispatch(getDebitAccountsList())
    dispatch(getPaymentCommencesList())
    dispatch(getPaymentTypesList())
  }, [dispatch])

  const continueApplication = () => {
    return navigate('/my-applications')
  }

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(parseInt(event.target.value, 10))
  }

  const handlePaymentDateChange = (event) => {
    setPaymentDate(parseInt(event.target.value, 10))
  }

  const handleSubmit = () => {
    if (!paymentOption || !selectedAccount || !paymentDate) {
      console.log('Please fill in all fields')
      return
    }

    const payload = {
      paymentTypeId: paymentOption,
      paymentCommenceId: paymentDate,
      debitAccountNumber: selectedAccount
    }

    // Dispatch the action to post the standing order
    dispatch(postStandingOrder(payload)).finally(() =>
      continueApplication()
    )
  }

  const onCloseRejectedModal = () => {
    setShowRejected(false)
  }

  const handleRejected = async () => {
    return dispatch(rejectStandingOrder()).finally(() => continueApplication())
  }

  return (
    <Container style={{ marginBottom: '10rem' }}>
      <RejectedModal open={showRejected} onClose={onCloseRejectedModal} onSubmit={handleRejected} />
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: '180px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '2rem',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Typography color='#fff' fontSize={36}>
          Credit Card Standing Order
        </Typography>
        <Typography color='#fff' fontSize={16}>
          Maximize benefits by setting up a credit card standing order – a
          scheduled payment from your account to your credit card.
        </Typography>
      </div>
      <div
        style={{
          background: '#fff',
          maxWidth: '100%',
          boxShadow: '3px 4px 8px rgba(0,0,0,0.25)',
          display: 'flex',
          overflowX: 'auto',
          padding: '20px 15px',
          minHeight: '80vh',
          flexDirection: 'column'
        }}
      >
        <Typography>
          Easily complete the below information to enroll for this service:
        </Typography>

        {/* Payment in Full Options (Only one can be selected) */}
        <FormControl
          component='fieldset'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '80%',
            margin: '3rem 0'
          }}
        >
          <RadioGroup
            value={paymentOption}
            onChange={handlePaymentOptionChange}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            {paymentTypesList.map((payment) => (
              <FormControlLabel
                control={<Radio value={payment.id} />}
                label={payment.name}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <InputLabel>Please debit my account number:</InputLabel>
        <FormControl style={{ width: '50%' }}>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedAccount}
            onChange={(event) => setSelectedAccount(event.target.value)}
          >
            {debitAccounts?.map((account, index) => (
              <MenuItem key={index} value={account.accountNumber}>
                {account.accountNumber}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography style={{ marginTop: '2rem' }}>
          Payment should commence on:
        </Typography>

        <RadioGroup
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '80%',
            margin: '1rem 0 3rem 0'
          }}
          value={paymentDate}
          onChange={handlePaymentDateChange}
        >
          {paymentCommencesList.map((payment) => (
            <FormControlLabel
              control={<Radio value={payment.id} />}
              label={payment.name}
            />
          ))}
        </RadioGroup>

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            justifyContent: 'center',
            marginTop: '20px'
          }}
        >
          <Button
            style={{
              width: '220px',
              backgroundColor: '#005FAA',
              borderRadius: '2rem'
            }}
            variant='contained'
            onClick={handleSubmit}
          >
            Continue
          </Button>
          <Button
            style={{
              width: '220px',
              borderRadius: '2rem',
              background: 'none',
              border: '1px solid #005FAA',
              color: '#005FAA'
            }}
            variant='contained'
            onClick={() => setShowRejected(true)}
          >
            Reject
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default CreditCardStandingOrder
