import { Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import bgImage from '../../../assets/CCImages/bg-title-page.png'
import { personalCreditCardAdapter, PersonalCreditCardList } from './auxiliarData/personalCreditCardInfo'
import { BusinessCreditCardList } from './auxiliarData/businessCreditCardInfo'
import CreditCardInfo from './CreditCardInfo/CreditCardInfo'
import { useParams } from 'react-router-dom'
import CreditCardsSelected from './CreditCardsSelected/CreditCardsSelected'
import CreditCardComparison from './CreditCardComparison/CreditCardComparison'
import { useDispatch, useSelector } from 'react-redux'
import { getCreditCards, setCardSelected } from './store'

const SelectCreditCard = () => {
  const dispatch = useDispatch()
  const { catalog } = useSelector((state) => state.availableCreditCard)
  const [cards, setCards] = useState([])
  const [selectedCC, setSelectedCC] = useState([])
  const [disabledCheck, setDisabledCheck] = useState(false)
  const [comparisson, setComparisson] = useState(false)

  useEffect(() => {
    if (selectedCC.length >= 3) setDisabledCheck(true)
    else setDisabledCheck(false)

    dispatch(setCardSelected(selectedCC))
  }, [selectedCC])

  const params = useParams()

  useEffect(() => {
    dispatch(getCreditCards())
  }, [])

  useEffect(() => {
    if (catalog) {
      const cards = personalCreditCardAdapter(catalog.options)
      setCards(cards)
    }
  }, [catalog])

  return (
    <>
      <Container style={{ marginBottom: '10rem' }}>
        {!comparisson ? (
          <>
            <div
              style={{
                backgroundImage: `url(${bgImage})`,
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '2rem'
              }}
            >
              <Typography color='#fff' fontSize={36}>
                {catalog.segment === 'Individual'
                  ? 'Personal Credit Cards.'
                  : 'Business Credit Cards.'}
              </Typography>
            </div>
            <Container>
              {cards.length > 0
                ? cards.map((cardInfo) => (
                    <CreditCardInfo
                      disabledCheck={disabledCheck}
                      setSelectedCC={setSelectedCC}
                      key={cardInfo.id}
                      cardInfo={cardInfo}
                    />
                  ))
                : null}
            </Container>
          </>
        ) : (
          <CreditCardComparison
            setComparisson={setComparisson}
            selectedCC={selectedCC}
            params={params}
          />
        )}
      </Container>
      {!comparisson && (
        <CreditCardsSelected
          setSelectedCC={setSelectedCC}
          setComparisson={setComparisson}
          selectedCC={selectedCC}
          params={params}
        />
      )}
    </>
  )
}

export default SelectCreditCard
