import React from 'react'
import CustomButton from '../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { APPLICATION_STEP } from '../../../options'
import { useDispatch } from 'react-redux'
import { getApplicationResume } from '../store'

const ActionContinue = ({ application }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [step, setStep] = React.useState(null)

  console.log('application ActionContinue', application)

  const handleOnClick = async () => {
    dispatch(getApplicationResume(application.id)).then(() => navigate(step))
  }

  React.useEffect(() => {
    if (application?.step) setStep(APPLICATION_STEP[application.step].path)
  }, [application])

  return (
    <React.Fragment>
      <CustomButton title='CONTINUE' onClick={handleOnClick} />
    </React.Fragment>
  )
}

export default ActionContinue
