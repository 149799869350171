import React from 'react'
import { Button } from '@mui/material'

const CustomButton = ({ title, onClick = async () => null }) => {
  return (
    <Button
      style={{
        width: '255px',
        backgroundColor: '#005FAA',
        borderRadius: '2rem'
      }}
      variant='contained'
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

export default CustomButton
