import { Card, CardContent, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { principalOptions } from './data'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  hasApplicationInProgress,
  startLimitIncrease,
} from './store'
import CardWithButton from '../../Components/CardWithButton/CardWithButton'
import { Spinner } from '../../../Components/BackDrop'
import { CUSTOM_LINKS } from './options'

const CreditCardApplication = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  // const { conditions } = useSelector((state) => state.application)
  const { applicationData: application} = useSelector((state) => state.application)

  const loadData = async () => {
    return [
      await dispatch(hasApplicationInProgress())
      // await dispatch(hasCreditLimitIncreaseAvailable())
    ]
  }

  useEffect(() => {
    loadData().finally(() => setLoading(false))
  }, [])

console.log("application", application)

const handleContinueLimitIncrease = () => {
  const sendData = { limitIncrease: true }
  dispatch(startLimitIncrease(sendData)).finally( () => {
    navigate(CUSTOM_LINKS['limit-increase'][application['limit-increase'].type])
  })
}

  return (
    <>
      <Spinner open={loading} />
      <Container>
        <Card variant='outlined'>
          <CardContent>
            <Typography
              width='fit-content'
              padding='0.3rem 4rem'
              borderRadius='1rem'
              bgcolor='#091242'
              color='#fff'
              fontWeight={600}
              margin='2rem 0'
            >
              Credit Cards
            </Typography>
            <Typography fontSize={40} fontWeight={400}>
              Expand Your Purchasing Power.
            </Typography>
            <Typography fontSize={20}>
              Enjoy the flexibility to choose the card that best fits your
              lifestyle with our diverse range of credit cards. Experience
              competitive rates and rewards that make spending more rewarding.
            </Typography>
          </CardContent>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            {application?.newApplication?.apply && (
              <CardWithButton
                card={principalOptions['apply-for-new-credit-card']}
                handleActive={() => navigate(CUSTOM_LINKS['newApplication'][application?.newApplication?.type])}
              />
            )}
            <CardWithButton
                card={principalOptions['my-applications']}
                handleActive={() => navigate(principalOptions['my-applications'].uri)}
              />
            {application?.offer?.apply && (
              <CardWithButton
                card={principalOptions['credit-offer']}
                handleActive={() => navigate(CUSTOM_LINKS['offer'][application?.offer?.type])}
              />
            )}
            {application['limit-increase']?.apply && (
              <CardWithButton
                card={principalOptions['limit-increase']}
                handleActive={handleContinueLimitIncrease}
              />
            )}
          </div>
        </Card>
      </Container>
    </>
  )
}

export default CreditCardApplication
