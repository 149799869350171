import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMissingDocs } from '../store'
import UploadFile from '../../../../Components/UploadFile'
import { deleteDocument, uploadDocument } from '../../../context/global-application'

const MissingDocuments = () => {
  const dispatch = useDispatch()
  const { missingDocs } = useSelector((state) => state.ccUploadDocuments)

  React.useEffect(() => {
    dispatch(getMissingDocs())
  }, [])

  const saveFile = async (file, item) => {
    const params = {
      documentType: item.docKey,
      documentName: file.filename,
      documentFile: file.base64,
      module: item.module,
      moduleId: item.id
    }
    const result = dispatch(uploadDocument(params))
    return result
  }

  const deleteFile = (file) => {
    console.log('deleteFile', file)
    dispatch(deleteDocument({ id: file.id }))
  }

  return (
    <React.Fragment>
      {missingDocs.map((item) => (
        <UploadFile
          label={item?.label}
          onChange={async (file) => await saveFile(file, item)}
          placeholder={`Upload file for ${item.key}`}
          onDelete={(file) => deleteFile(file)}
          key={item.id}
        />
      ))}
    </React.Fragment>
  )
}

export default MissingDocuments
