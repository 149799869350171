import { UPLOAD_DOCUMENTS } from '../containers/options'

// const createAdapterOld = () => {
//   return (data) => {
//     let newData = { ...data }
//     const internalKeys = Object.keys(UPLOAD_DOCUMENTS)

//     if(internalKeys.includes(data?.key)) newData.adapter = UPLOAD_DOCUMENTS[data.key].adapter
//     else newData.adapter = UPLOAD_DOCUMENTS.default.adapter

//     return newData.adapter(data)
//   }
// }

const createAdapter = (item) => {
  console.log("Item key");
    
  const adapterKey = UPLOAD_DOCUMENTS[item?.docKey]?.adapter || UPLOAD_DOCUMENTS.default.adapter
  return adapterKey(item)
}

export const missingDocsAdapter = (data) => {
  return data.map(createAdapter)
}
