import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useMediaQuery } from '@material-ui/core'
import { Button, Typography } from '@mui/material'
import { useStyles } from './StartCreditCardModalStyle'
import StartCreditCard from '../../assets/Images/start-credit-card.png'
import ModalContainer from '../AtomicDesign/atoms/ModalContainer/ModalContainer'

const StartCreditCardModal = ({ options, onAccept }) => {
  const classes = useStyles()
  const isSmallMidScreen = useMediaQuery('(max-width: 872px)')

  const [openModal, setOpenModal] = useState(true)

  const acceptOfferAction = async () => {
    onAccept().finally(() => {
      setOpenModal(false)
    })
  }

  return (
    <>
      <ModalContainer
        open={openModal}
        width={824}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div className={classes.modalWrapper}>
          <div className={classes.container}>
            {!isSmallMidScreen && (
              <div className={classes.imageContainer}>
                <img
                  src={StartCreditCard}
                  alt='credit cards'
                  className={classes.image}
                />
              </div>
            )}

            <div className={classes.contentContainer}>
              <Typography className={classes.title}>
                {options.title}
              </Typography>

              <Typography className={classes.description}>
                <div dangerouslySetInnerHTML={{ __html: options.description }} />
              </Typography>

              <Button className={classes.primaryButton} onClick={acceptOfferAction}>
                YES, I WISH TO PROCEED
              </Button>
              <Button
                className={classes.secondaryButton}
                onClick={() => setOpenModal(false)}
              >
                NO, I'LL CONSIDER IT LATER
              </Button>
            </div>
          </div>
        </div>
      </ModalContainer>

      <Toaster />
    </>
  )
}

export default StartCreditCardModal
