import React from 'react'
import CustomButton from '../components/CustomButton'
import { APPLICATION_STEP } from '../../../options'
import { useNavigate } from 'react-router-dom'
import { getApplicationResume } from '../store'
import { useDispatch } from 'react-redux'

const PendingSignature = ({ application }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [step, setStep] = React.useState(null)

  console.log('application PendingSignature', application)

  const handleOnClick = async () => {
    dispatch(getApplicationResume(application.id)).then(() => navigate(step))
  }

  React.useEffect(() => {

    if (application.digitalSign) setStep(APPLICATION_STEP[application.step]?.path)
    else setStep(APPLICATION_STEP[application.step]?.path)

  }, [application])

  return (
    <React.Fragment>
      {step && <CustomButton title='SIGN DOCUMENTS' onClick={handleOnClick} />}
    </React.Fragment>
  )
}

export default PendingSignature
