import { formatBelizeCurrency } from '../../../../Utils/utils'

export const defaultAdapter = () => {
  return (data) => {
    return {
      ...data,
      label: `${
        data?.extraDetails?.ammount
          ? formatBelizeCurrency(data.extraDetails.ammount)
          : ''
      } - ${data?.extraDetails?.bankName || ' NO BANK NAME'}`,
    }
  }
}
