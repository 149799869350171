import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSignBranches,
  getSignDocuments,
  postSignDocuments,
  postPickupBranch,
//   postSigningAppointment
} from './store'
import SignDocumentPreview from '../../Components/SignDocumentPreviw/SignDocumentPreview'
import { useNavigate } from 'react-router-dom'
// import { CardSimpleAlertMessage } from '../../Components/Cards/CardSimpleAlertMessage/CardSimpleAlertMessage'

const SignDocuments = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [allDocumentsDownload, setAllDocumentsDownload] = useState(false)
  const [name, setName] = useState('')
  const [selectedBranch, setSelectedBranch] = useState('')
//   const [appointmentDate, setAppointmentDate] = useState('2024-12-31')
//   const [appointmentTime, setAppointmentTime] = useState('2024-12-31 8:30:00')

  const { documents, branches, pending } = useSelector(
    (state) => state.ccSignDocuments
  )

  useEffect(() => {
    dispatch(getSignDocuments())
    dispatch(getSignBranches())
  }, [dispatch])

  const handleContinue = async () => {
    try {
      // Sign documents
      const signResult = await dispatch(postSignDocuments({ signingName: name })).unwrap()
      console.log('Documents signed successfully:', signResult)

      // Pickup branch
      const pickupResult = await dispatch(postPickupBranch({ branchId: selectedBranch })).finally(() => navigate('/credit-card/standing-order'))
      console.log('Branch pickup registered successfully:', pickupResult)
    } catch (error) {
      console.error('Error processing steps:', error)
      // alert('An error occurred. Please try again.')
    }
  }

  return (
    <div style={{ marginBottom: '10rem' }}>
      {documents?.documents?.length > 0 &&
      documents?.documents[0]?.hasClientSigned === true ? (
        <div
          style={{
            background: '#fff',
            padding: '20px 15px',
            boxShadow: '3px 4px 8px rgba(0,0,0,0.25)'
          }}
        >
          <Typography>
            Your document has been signed and is waiting for approval.
          </Typography>
        </div>
      ) : (
        <div
          style={{
            background: '#fff',
            padding: '20px 15px',
            boxShadow: '3px 4px 8px rgba(0,0,0,0.25)'
          }}
        >
          <Typography>
            Congratulations, your credit card has been approved. You can sign
            all the required documents electronically.
          </Typography>
          <SignDocumentPreview
            applications={documents?.documents || []}
            onAllDownload={() => setAllDocumentsDownload(true)}
          />
          {allDocumentsDownload ? (
            <>
              <div style={{ margin: '45px 0' }}>
                <InputLabel>Please enter your full name</InputLabel>
                <Input
                  placeholder='Daniela Ortega'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    border: '0.5px solid rgba(0, 0, 0, 0.48)',
                    borderRadius: 3,
                    padding: '0px 14px',
                    width: '50%'
                  }}
                />
              </div>
              <div style={{ margin: '45px 0' }}>
                <InputLabel>
                  Please select one of our locations to pick up your card from
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    labelId='branch-select-label'
                    id='branch-select'
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                  >
                    {branches.branches?.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ margin: '45px 0' }}></div>
              <Button
                style={{
                  width: '255px',
                  backgroundColor: '#005FAA',
                  borderRadius: '2rem'
                }}
                variant='contained'
                onClick={handleContinue}
              >
                Continue
              </Button>
            </>
          ) : (
            <InputLabel>Download all your documents to continue</InputLabel>
          )}
        </div>
      )}
    </div>
  )
}

export default SignDocuments
