import React from 'react'
import { useSelector} from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CardCurrentApplication from '../../Components/CardCurrentApplication/CardCurrentApplication'
import { useStyles } from './CardCurrentApplicationStyle'
import { EmailPreviewLoading } from '../../../Pages/components/skeleton'

const CompletedApplication = () => {
  const classes = useStyles()
  const { completeData: applications, pending } = useSelector((state) => state.ccCurrentApplication)

  return (
    <Stack spacing={{ xs: 4, md: 2 }} direction='column' mb={3} width='100%'>
      <Accordion elevation={0} className={classes.Accordian}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='body2' className={classes.AccordianHeader}>
            My History Applications
          </Typography>
        </AccordionSummary>
        {pending.completed ? (
          <EmailPreviewLoading />
        ) : applications?.length > 0 ? (
          <AccordionDetails
            style={{
              gap: '2rem',
              display: 'flex',
              flexDirection: 'column'
            }}
            sx={{ backgroundColor: '#FFFF', padding: 10 }}
            mt={3}
          >
            {applications.map((app) => (
              <CardCurrentApplication
                key={app.id}
                CurrentDetails={app}
              ></CardCurrentApplication>
            ))}
          </AccordionDetails>
        ) : (
          <Typography variant='body2' className={classes.AccordianHeader}>
            No current applications found.
          </Typography>
        )}
      </Accordion>
    </Stack>
  )
}

export default CompletedApplication
