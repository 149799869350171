import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwtCC from '../../../../core/jwt/cc/jwtServiceCreditCard'

const ccService = new UseJwtCC({})



export const getCreditCards = createAsyncThunk('appAvailableCreditCard/getCreditCards', async () => {
  const response = await ccService.getCreditCards()
  return response.data
})


export const appAvailableCreditCardSlice = createSlice({
  name: 'appAvailableCreditCard',
  initialState: {
    catalog: [],
    selectedCC: {},
    pending: true,
  },
  reducers: {
    setCardSelected: (state, action) => {
      state.selectedCC = action.payload
    }
  },
  extraReducers: {
    [getCreditCards.fulfilled]: (state, action) => {
      state.catalog = action.payload
      state.pending = false
    },
    [getCreditCards.pending]: (state) => {
      state.pending = true
    }
  }
})

export const { setCardSelected } = appAvailableCreditCardSlice.actions

export default appAvailableCreditCardSlice.reducer

