import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../Input'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setFormData } from '../store'

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Employer email is required'),
  confirm_email: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm email is required')
})

const EmailConfirmation = () => {
  const dispatch = useDispatch()
  const { errors, formData } = useSelector((state) => state.ccProofOfIncome)

  const formik = useFormik({
    initialValues: {
      email: '',
      confirm_email: ''
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values)
    }
  })

  React.useEffect(() => {
    if (formik.errors.email || formik.errors.confirm_email) {
      dispatch(setFormData({ employerEmail: '' }))
      return
    }
    dispatch(setFormData({ employerEmail: formik.values.email }))
  }, [formik?.errors])

  React.useEffect(() => {
    if (formData?.employerEmail) {
      formik.setValues({
        email: formData?.employerEmail,
        confirm_email: formData?.employerEmail
      })
    }
  }, [formData])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
        mt={3}
        alignItems='flex-start'
        mb={3}
        gap={3}
      >
        <Input
          Lable='Enter your employer’s email address'
          name='email'
          value={formik?.values?.email}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={(formik?.touched?.email && formik?.errors?.email) || errors?.employerEmail}
          id='email'
        />

        <Input
          Lable='Confirm your employer’s email address'
          name='confirm_email'
          value={formik?.values?.confirm_email}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={
            (formik?.touched?.confirm_email && formik?.errors?.confirm_email) || errors?.employerEmail
          }
          id='confirm_email'
        />
      </Stack>
    </form>
  )
}

export default EmailConfirmation
