import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material'
// import HeaderCurrentLoan from "../../molecules/HeaderCurrentLoan/HeaderCurrentLoan"
import HeaderCurrentLoan from '../../../Components/AtomicDesign/molecules/HeaderCurrentLoan/HeaderCurrentLoan'
import InfoCard from '../../../Components/AtomicDesign/molecules/InfoCard/InfoCard'

//Icons
import Calendar from '../../../assets/Icons/calendar-icon.png'
import CalendarTerm from '../../../assets/Icons/video-time.png'
import Amount from '../../../assets/Icons/coin.png'
import InfoError from '../../../assets/Icons/Error-Icon.png'
import InfoSuccess from '../../../assets/Icons/calendar-circle.png'
//styles
import { useStyles } from './CardCurrentApplicationStyles.js'
import CurrentLoanDetails from '../../../Components/AtomicDesign/molecules/CurrentLoanDetails/CurrentLoanDetails'
import Separator from '../../../Components/AtomicDesign/atoms/Separator/Separator'
import { Title } from '@mui/icons-material'

const CardCurrentApplication = ({ CurrentDetails, children }) => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width: 464px)')

  // console.table(CurrentDetails)
  return (
    <Card className={classes.card}>
      <div className={classes.greenLine}>{/* <FiberManualRecord /> */}</div>
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
          <img
            src={CurrentDetails?.cardDetails?.img}
            alt=''
            style={{ width: !isSmallScreen ? 332 : 186 }}
          />
          <Grid container>
            <Grid container direction='row' alignItems='center' xs={12} md={12}>
              <Typography margin={'0px'} className={classes.CustomTitle}>
                {CurrentDetails?.cardDetails?.category || 'Credit Limit Increase'}
              </Typography>
              <span className={classes.separator}>•</span>
              <Typography
                Text={'Approved'}
                margin={'0px'}
                color={
                  CurrentDetails.status.id === 1 ||
                  CurrentDetails.status.id === 2
                    ? '#56AF31'
                    : CurrentDetails.status.id === 3
                    ? '#f2994a'
                    : CurrentDetails.status.id === 4 ||
                      CurrentDetails.status.id === 8 ||
                      CurrentDetails.status.id === 9
                    ? '#f2994a'
                    : CurrentDetails.status.id === 5
                    ? '#56AF31'
                    : CurrentDetails.status.id === 6
                    ? '#56AF31'
                    : CurrentDetails.status.id === 7
                    ? '#FF0606'
                    : CurrentDetails.status.id === 10
                    ? '#0F5CAC'
                    : CurrentDetails.status.id === 11
                    ? '#0F5CAC'
                    : CurrentDetails.status.id === 12
                    ? '#0F5CAC'
                    : '#0F5CAC'
                }
                fontWeight={'100'}
                fontSize={'12px'}
              >
                {CurrentDetails.status.id === 1 ||
                CurrentDetails.status.id === 2
                  ? 'Created'
                  : CurrentDetails.status.id === 3
                  ? 'Not Started'
                  : CurrentDetails.status.id === 4 ||
                    CurrentDetails.status.id === 8 ||
                    CurrentDetails.status.id === 9
                  ? 'In Progress'
                  : CurrentDetails.status.id === 5
                  ? 'Approved'
                  : CurrentDetails.status.id === 6
                  ? 'Approved With Conditions'
                  : CurrentDetails.status.id === 7
                  ? 'Declined'
                  : CurrentDetails.status.id === 10
                  ? 'Pending Signature'
                  : CurrentDetails.status.id === 11
                  ? 'Signed'
                  : CurrentDetails.status.id === 12
                  ? 'Completed'
                  : 'Contact Us'}
              </Typography>
            </Grid>
            <Separator />
            <CurrentLoanDetails
              Icon={Calendar}
              Key={'START DATE'}
              Value={
                CurrentDetails?.datesDetails?.applicationDate
                  ? new Date(
                      CurrentDetails.datesDetails.applicationDate
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    })
                  : '-- -- --'
              }
            />
            <CurrentLoanDetails
              Icon={Amount}
              Key={'AMOUNT'}
              Value={`$ ${CurrentDetails?.limitDetails?.requested}`}
            />
            <CurrentLoanDetails
              Icon={Calendar}
              Key={'SUBMISSION DATE'}
              Value={
                CurrentDetails?.datesDetails?.submittedDate
                  ? new Date(
                      CurrentDetails.datesDetails.submittedDate
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    })
                  : '-- -- --'
              }
            />
            <CurrentLoanDetails
              Icon={CalendarTerm}
              Key={'Co-applicants'}
              Value={
                CurrentDetails?.supplementaryCardHolder === true ? 'YES' : 'NO'
              }
            />
            {children}
          </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

export default CardCurrentApplication
