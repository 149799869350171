import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UseJwt from '../../../../auth/jwt/JwtCreditCard';

// GET DEBIT ACCOUNTS LIST
export const postStandingOrder = createAsyncThunk(
  'appStandingOrder/getDebitAccountsList',
  async (payload) => {
    const response = await UseJwt.postStandingOrder(payload);
    return response.data;
  }
);

// POST STANDING ORDER
export const getDebitAccountsList = createAsyncThunk(
  'appStandingOrder/postStandingOrder',
  async () => {
    const response = await UseJwt.getStandingOrderAccountList();
    return response.data;
  }
);

// Fetch Payment Types List
export const getPaymentTypesList = createAsyncThunk('ccStandingOrder/getPaymentTypesList', async () => {
    const response = await UseJwt.getPaymentTypeList();
    return response.data
  })

// Fetch Payment Commences List
export const getPaymentCommencesList = createAsyncThunk('ccStandingOrder/getPaymentCommencesList', async () => {
    const response = await UseJwt.getPaymentCommence();
    return response.data;
  }
)

export const rejectStandingOrder = createAsyncThunk('ccStandingOrder/rejectStanding', async () => {
  const response = await UseJwt.rejectStandingOrder()
  return response.data
})

const initialState = {
  pending: false,
  success: false,
  error: null,
  debitAccounts: null,
  standingOrderResponse: null,
  paymentTypesList: [], 
  paymentCommencesList: [], 
};

export const ccStandingOrderSlice = createSlice({
  name: 'ccStandingOrder',
  initialState,
  reducers: {
    resetStandingOrderState: (state) => {
      state.pending = false;
      state.success = false;
      state.error = null;
      state.debitAccounts = null;
      state.standingOrderResponse = null;
      state.paymentTypesList = [];
      state.paymentCommencesList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDebitAccountsList.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getDebitAccountsList.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.debitAccounts = action.payload;
      })
      .addCase(postStandingOrder.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(postStandingOrder.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.standingOrderResponse = action.payload;
      })
      .addCase(getPaymentTypesList.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getPaymentTypesList.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.paymentTypesList = action.payload;
      })
      .addCase(getPaymentCommencesList.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getPaymentCommencesList.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.paymentCommencesList = action.payload;
      })
  },
});

export const { resetStandingOrderState } = ccStandingOrderSlice.actions;

export default ccStandingOrderSlice.reducer;
