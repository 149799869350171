export const creditCardsOptions = (creditCards) => {
  const filteredMappedCards = creditCards
    .filter((card) => card.qualify)
    .map((card, index) => {
      const cardNumberNoSpaces = card.cardNumber.replace(/\s/g, '')
      return {
        id: `${cardNumberNoSpaces.slice(0,4)}${cardNumberNoSpaces.slice(-4)}`,
        name: `xxxx xxxx xxxx ${cardNumberNoSpaces.slice(-4)} (${card.franchise})`
      }
    })
  return filteredMappedCards
}
