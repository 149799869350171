import React, { useEffect, useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useStyles } from './CreditLimitIncreaseRequestStyle'
import { ChevronLeft } from '@mui/icons-material'
import { Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'
import Currency from '../../Components/Inputs/currency2'
import { useDispatch, useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast'
import {
  createCreditLimitRequest,
  getCreditCardsInfo,
  getInsuranceList,
  getLimitDurations,
  getLimitTypes,
  setSelectedCard
} from './store'
import Insurance from './Insurance/Insurance'
import { Spinner } from '../../../Components/BackDrop'
import SelectWithOptions from '../../../Components/Inputs/SelectWithOptions'
import { creditCardsOptions } from './adapter'
import { findSelectedCard } from './settings'
import * as Yup from 'yup'
import { updateApplicationStep } from '../../context/global-application'
import { APPLICATION_STEP } from '../../options'
import { adAssignLenderCall, adCompleteCall } from '../UploadDocuments/store'

const CreditLimitIncreaseRequest = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isSmallMidScreen = useMediaQuery('(max-width: 950px)')
  const [loading, setLoading] = useState(true)
  const [insuranceSelected, setInsuranceSelected] = useState(null)
  const { limitTypes, limitDurations, creditCards, selectedCard } = useSelector(
    (state) => state.limitIncreaseRequest
  )

  const [initialFormValues] = useState({
    limitType: '',
    limitDuration: '',
    creditCard: '',
    currentCreditLimit: 0,
    newCreditLimit: ''
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: async (values) => {
      if (formik.isValid) {
        console.log('Submitting values:', values)
        await submitRequest()
      } else {
        formik.setTouched({
          limitType: true,
          creditCard: true,
          newCreditLimit: true
        })
      }
    },
    validationSchema: Yup.object().shape({
      limitType: Yup.object()
        .shape({
          id: Yup.string().required('Limit type is required'),
        })
        .nullable()
        .required('Limit type is required'),
      creditCard: Yup.object()
        .shape({
          id: Yup.string().required('Credit card is required'),
        })
        .nullable()
        .required('Credit card is required'),
      limitDuration: Yup.object()
        .shape()
        .nullable()
        .when('limitType?.id', {
          is: '1',
          then: Yup.object().required('Limit duration is required'),
        }),
      newCreditLimit: Yup.number()
        .required('New credit limit is required')
        .min(
          selectedCard?.creditLimit || 0,
          `Minimum limit is ${selectedCard?.creditLimit || 0}`
        )
        .max(
          selectedCard?.newLimitOffer || Infinity,
          `Maximum limit is ${selectedCard?.newLimitOffer || Infinity}`
        )
    })

  })

  const onChangeInput = (e, value, field) => {
    const { name } = field
    let inputValue = value
    if (value === '' || !value.length) {
      inputValue = '0'
    }
    formik.setFieldTouched(name)
    formik.setFieldValue(name, inputValue)
  }

  const setCreditCardSelectedInfo = (form, field, value) => {
    const cardInfo = findSelectedCard(value, creditCards)
    dispatch(setSelectedCard(cardInfo))
    form.setFieldValue('currentCreditLimit', cardInfo?.creditLimit)
    form.setFieldValue(field.name, value)
  }

  const loadData = async () => {
    return [
      await dispatch(getInsuranceList()),
      await dispatch(getLimitTypes()),
      await dispatch(getLimitDurations()),
      await dispatch(getCreditCardsInfo())
    ]
  }

  const submitRequest = async () => {
    setLoading(true);
    const { newCreditLimit, limitType, limitDuration, creditCard } = formik.values;
  
    const data = {
      requestedLimit: Number(newCreditLimit),
      limitIncreaseTypeId: limitType.id,
      limitIncreseTempTimeId: limitDuration.id,
      increaseCard: {
        franchise: selectedCard.franchise,
        currentLimit: selectedCard.creditLimit,
        cardNumber: creditCard.id
      },
    };

    if (insuranceSelected) data.selectedInsurances = [{ insuranceOptionId: insuranceSelected }]

    dispatch(createCreditLimitRequest(data)).then(async () => {
      await dispatch(adAssignLenderCall()).then(async () => {
        await dispatch(adCompleteCall()).then(() => navigate('/my-applications'))
      })
    }).finally(() => setLoading(false))
  }


  useEffect(() => {
    loadData().finally(() => setLoading(false))
  }, [])

  React.useEffect(() => {
    dispatch(updateApplicationStep(APPLICATION_STEP['limit-increase-request-cc'].key))
  }, [])

  return (
    <React.Fragment>
      <Spinner open={loading} />
      <Toaster />
      <section>
        <Box className={classes.container}>
          <Typography className={classes.Header}>
            Credit Limit Increase Request
          </Typography>
        </Box>
        <Box>
          <div className={classes.ContentWrapper}>
            <FormikProvider value={formik} onSubmit={formik.handleSubmit}>
              <section>
                <div>
                  <div className={classes.SectionHeader}>
                    <span className={classes.Number}>1</span>
                    <h3 className={classes.Title}>Limit Type</h3>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1.5rem',
                      flexWrap: isSmallMidScreen ? 'wrap' : 'nowrap'
                    }}
                  >
                    <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                      <Field name='limitType'>
                        {({ field, form, meta }) => (
                          <>
                            <SelectWithOptions
                              {...field}
                              Label='Limit type'
                              placeholder='Please select an option'
                              options={limitTypes}
                              error={(meta.touched && meta.error) ? meta.error.id : ''}
                              value={meta.value}
                              onBlur={() => {
                                formik.setFieldTouched(field.name)
                              }}
                              onChange={(e, value, reason) => {
                                console.log(value)
                                if (reason == 'clear')
                                  form.setFieldValue(field.name, '')
                                else {
                                  value && form.setFieldValue(field.name, value)
                                }
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    {formik.values.limitType.id === 1 && (
                      <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                        <Field name='limitDuration'>
                          {({ field, form, meta }) => (
                            <>
                              <SelectWithOptions
                                {...field}
                                Label='Duration of temporary limit'
                                placeholder='Please select an option'
                                options={limitDurations}
                                error={(meta.touched && meta.error) ? meta.error.id : ''}
                                value={meta.value}
                                onBlur={() => {
                                  formik.setFieldTouched(field.name)
                                }}
                                onChange={(e, value, reason) => {
                                  if (reason == 'clear')
                                    form.setFieldValue(field.name, '')
                                  else {
                                    value &&
                                      form.setFieldValue(field.name, value)
                                  }
                                }}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <div className={classes.SectionHeader}>
                    <span className={classes.Number}>2</span>
                    <h3 className={classes.Title}>Credit Card</h3>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1.5rem',
                      flexWrap: isSmallMidScreen ? 'wrap' : 'nowrap'
                    }}
                  >
                    <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                      <Field name='creditCard'>
                        {({ field, form, meta }) => (
                          <>
                            <SelectWithOptions
                              {...field}
                              Label='Choose the credit card that will undergo a credit limit increase'
                              placeholder='Please select an option'
                              options={creditCardsOptions(creditCards)}
                              error={(meta.touched && meta.error) ? meta.error.id : ''}
                              value={meta.value}
                              onBlur={() => {
                                formik.setFieldTouched(field.name)
                              }}
                              onChange={(e, value, reason) => {
                                if (reason == 'clear')
                                  form.setFieldValue(field.name, '')
                                else {
                                  value &&
                                    setCreditCardSelectedInfo(
                                      form,
                                      field,
                                      value
                                    )
                                }
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <div className={classes.SectionHeader}>
                    <span className={classes.Number}>3</span>
                    <h3 className={classes.Title}>New Limit Request Amount</h3>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1.5rem',
                      flexWrap: isSmallMidScreen ? 'wrap' : 'nowrap'
                    }}
                  >
                    <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                      <Field name='currentCreditLimit'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='Your current credit limit'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                              readOnly
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                      <Field name='newCreditLimit'>
                        {({ field, form, meta }) => (
                          <>
                            <Currency
                              Lable='Enter the new credit limit to request'
                              error={meta.touched && meta.error}
                              onChange={(e, value) =>
                                onChangeInput(e, value, field)
                              }
                              name={field.name}
                              value={meta.value}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <div className={classes.SectionHeader}>
                    <span className={classes.Number}>4</span>
                    <h3 className={classes.Title}>Insurance</h3>
                  </div>
                  <Insurance onSelectedInsurance={setInsuranceSelected} />
                </div>
              </section>
            </FormikProvider>
          </div>
        </Box>
        <Box>
          <div
            style={{
              margin: '40px 0',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2rem'
            }}
          >
            <Button
              className={classes.BtnBack}
              onClick={() =>
                navigate('/select-options')
              }
            >
              <ChevronLeft />
              GO BACK
            </Button>
            <Button
              className={classes.BtnNext}
              onClick={formik.handleSubmit}
              type='button'
            >
              SUBMIT
            </Button>
          </div>
        </Box>
      </section>
    </React.Fragment>
  )
}

export default CreditLimitIncreaseRequest
