import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    // marginBottom: '10rem'
  },
  cardContainer: {
    background: '#fff',
    maxWidth: '100%',
    boxShadow: '3px 4px 8px rgba(0,0,0,0.25)',
    overflowX: 'auto',
    padding: '2% 6%'
  },
  secureMessage: {
    marginBottom: '40px'
  },
  inputContainer: {
    margin: '45px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 14
  },
  dateTimeContainer: {
    display: 'flex',
    gap: '1rem'
  },
  inputLabel: {
  },
  input: {
    border: '.5px solid rgba(0, 0, 0, 0.48)',
    width: '50%',
    padding: '0px 14px',
    borderRadius: 3
  },
  select: {
    padding: 0
  },
  errorMessage: {
    color: "red",
    margin: "8px 0px 0px 0px",
    fontFamily: 'Arial, sans-serif',
    fontSize: 13,
    fontWeight: 400
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem'
  },
  button: {
    width: '100%',
    maxWidth: '420px',
    backgroundColor: '#005FAA',
    color: 'white',
    borderRadius: '2rem',
    padding: '0.75rem 1.5rem',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center'
  }
}))
