import appSelectApplication from '../CreditCardPhaseOne/Pages/CreditCardApplication/store'
import appHomeCreditCard from '../CreditCardPhaseOne/Pages/home/store'
import appPersonalInformationReview from '../CreditCardPhaseOne/Pages/PersonalInformationReviewForm/store'
import appComparisonSlice from '../CreditCardPhaseOne/Pages/SelectCreditCard/CreditCardComparison/store'
import appAvailableCreditCardSlice from '../CreditCardPhaseOne/Pages/SelectCreditCard/store'
import appAdditionalApplicants from '../CreditCardPhaseOne/Pages/AdditionalApplicants/store'
import appGlobalApplication from '../CreditCardPhaseOne/context/global-application'
import appCreditLimitRequestSlice from '../CreditCardPhaseOne/Pages/CreditLimitRequest/store'
import appMonthlyIncomeSlice from '../CreditCardPhaseOne/Pages/MonthlyIncome/store'
import appMonthlyExpensesSlice from '../CreditCardPhaseOne/Pages/MonthlyExpenses/store'
import appProofOfIncome from '../CreditCardPhaseOne/Pages/ProofOfIncome/store'
import appStepUploadMissingDocs from '../CreditCardPhaseOne/Pages/UploadDocuments/store'
import appUploadDocuments from '../CreditCardPhaseOne/StoreCC/upload-store'
import appApplicationsHistorySlice from '../CreditCardPhaseOne/Pages/MyApplications/store'
import  appSignDocumentsSlice  from '../CreditCardPhaseOne/Pages/SignDocuments/store'
import ccStandingOrderSlice from '../CreditCardPhaseOne/Pages/CreditCardStandingOrder/store'
import appLimitIncreaseRequestSlice from '../CreditCardPhaseOne/Pages/CreditLimitIncreaseRequest/store'

const rootReducerCreditCard = {
  globalApplication: appGlobalApplication,
  authentication: appHomeCreditCard,
  application: appSelectApplication,
  availableCreditCard: appAvailableCreditCardSlice,
  ccApplicationDocuments: appUploadDocuments,
  personalInformationReview: appPersonalInformationReview,
  additionalApplicants: appAdditionalApplicants,
  comparison: appComparisonSlice,
  creditLimitRequest: appCreditLimitRequestSlice,
  ccMonthlyIncome: appMonthlyIncomeSlice,
  ccMonthlyExpenses: appMonthlyExpensesSlice,
  ccProofOfIncome: appProofOfIncome,
  ccUploadDocuments: appStepUploadMissingDocs,
  limitIncreaseRequest: appLimitIncreaseRequestSlice,
  ccCurrentApplication: appApplicationsHistorySlice,
  ccSignDocuments: appSignDocumentsSlice,
  ccStandingOrder: ccStandingOrderSlice
}

export default rootReducerCreditCard
