import React from 'react'
import { Box } from '@mui/system'
import { acceptOfferOlaCC, getOfferOlaCC } from './store'
import { useStyles } from './NonCustomerPageStyle'
import Spinner from '../../Components/BackDrop/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import Congratulations from '../../Components/Congratulations/Congratulations'
import StartCreditCardModal from '../../Components/Modals/StartCreditCardModal'
import { useNavigate } from 'react-router-dom'
import { TYPE_OFFER } from './options'
import toast from 'react-hot-toast'

const ThankYouPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { creditApplicationData, pending } = useSelector((state) => state.olaThankYouReducer)
  React.useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  React.useEffect(() => {
    dispatch(getOfferOlaCC())
  }, [])

  const handleAccept = async () => {
    return await dispatch(acceptOfferOlaCC()).then((result) => {

      // console.log('result', result)
      if (result?.error) return toast.error('Error')
      if (!result?.payload?.applicationAddedSuccessfully) return toast.success(result.payload.message)

      return  navigate(TYPE_OFFER[creditApplicationData.type].path)
    })
  }

  return (
    <Box className={classes.MainContianer}>
      <Spinner open={pending} />
      { !pending && <Congratulations /> }
      { !pending && creditApplicationData && <StartCreditCardModal options={creditApplicationData} onAccept={handleAccept} /> }
    </Box>
  )
}

export default ThankYouPage
