// export const baseUrl = "http://[::1]:3002"
//export const baseUrl = 'https://bblolatp1.belizebank.com' // ! SECURE DEV ENV
// export const baseUrl = 'http://190.110.54.245:9070' // 8092
export const baseUrl = 'https://testbblonlineloans.belizebank.com'
// export const baseUrl = 'https://bblolatp2.belizebank.com' // ! DEV
//export const baseUrl = 'http://192.168.16.36:3002' // ! DEV_LOCAL

/*
  root /usr/share/nginx/customer1.bblbze.com/html;
*/
