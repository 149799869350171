import React from 'react'
import { Alert, Box, Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useStyles } from './UploadDocumentsStyle'
import Button from '../../Components/Button/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { transformFileToBase64 } from '../../CreditCardPhaseOne/utils'

const UploadFile = ({
  types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'],
  size = 2, // MB
  multiple = false,
  onChange,
  onDelete,
  convertToBase64 = true,
  label = 'Upload File*',
  placeholder = 'Upload File',
  helperText = 'Only pdf, image documents are permitted with a maximum of 2MB'
}) => {
  const classes = useStyles()
  const [documentUploaded, setDocumentUploaded] = React.useState(null)
  const ssnFileRef = React.useRef([])
  const [file, setFile] = React.useState({
    filename: null,
    error: null,
    base64: null
  })

  const handleOnchange = (event) => {
    const { files } = event.target
    const file = files[0]
    // validate size
    if (file.size > size * 1024 * 1024) {
      setFile({ filename: null, error: 'File size is too large', base64: null })
      return
    }

    if (convertToBase64) {
      transformFileToBase64(file)
        .then((base64) => {
          setFile({ filename: file.name, error: null, base64 })
          onChange && onChange({ filename: file.name, base64 }).then((response) => {
            setDocumentUploaded(response?.payload || null)
          })
        })
        .catch((error) => {
          setFile({ filename: null, error, base64: null })
        })
    } else {
      setFile({ filename: file.name, error: null, base64: null })
    }
  }

  return (
    <div style={{ margin: '24px 0' }}>
      <Typography className={classes.Title}>{label}</Typography>

      <Button
        Title={placeholder}
        variant='h7'
        className={classes.ButtonUploadFile}
        endIcon={<AttachFileIcon />}
        onClick={() => ssnFileRef.current.click()}
      />

      <div>
        <input
          type='File'
          accept={types.join(',')}
          value=''
          style={{ display: 'none' }}
          ref={ssnFileRef}
          onChange={(event) => handleOnchange(event)}
        />

        <Typography className={classes.HelperText}>{helperText}</Typography>
        {file.error?.message && (
          <Typography className={classes.errorMessage}>
            {file.error?.message}
          </Typography>
        )}
        {file.filename && (
          <p className={classes.FileName}>
            {`${String(file.filename || '').substring(0, 32)}...`}
            <span>
              <ClearIcon
                className={classes.deleteFileIcon}
                onClick={() => {
                  setFile({ filename: null, error: null, base64: null })
                  onDelete && onDelete(documentUploaded)
                }}
              />
            </span>
          </p>
        )}
      </div>
    </div>
  )
}

export default UploadFile
