import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/JwtCreditCard'
import { SEGMENT_LIMITS } from '../CreditLimitRequest/CreditLimitOptions'

export const getInsuranceList = createAsyncThunk(
  'limitIncreaseRequest/getInsuranceList',
  async () => {
    const response = await UseJwt.getInsuranceList()
    return response.data || []
  }
)

export const getLimitTypes = createAsyncThunk(
  'limitIncreaseRequest/getLimitTypes',
  async () => {
    const request = await UseJwt.getLimitTypes()
    return request.data || []
  }
)
export const getLimitDurations = createAsyncThunk(
  'limitIncreaseRequest/getLimitDurations',
  async () => {
    const request = await UseJwt.getLimitDurations()
    return request.data || []
  }
)
export const getCreditCardsInfo= createAsyncThunk(
  'limitIncreaseRequest/getCreditCardsInfo',
  async () => {
    const request = await UseJwt.getUserCreditCardInfo()
    return request.data?.cardInfo || []
  }
)

export const createCreditLimitRequest = createAsyncThunk(
  'limitIncreaseRequest/createCreditLimitIncreaseRequest',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await UseJwt.createCreditLimitRequest(requestData)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const initialState = {
  pending: true,
  insuranceList: [],
  selectedInsurance: null,
  limits: SEGMENT_LIMITS.Individual,
  limitTypes: [],
  limitDurations: [],
  creditCards: [],
  selectedCard: null
}

export const appLimitIncreaseRequestSlice = createSlice({
  name: 'limitIncreaseRequest',
  initialState,
  reducers: {
    setSelectedInsurance: (state, action) => {
      state.selectedInsurance = {
        id: action.payload.insuranceOptionID,
        title: action.payload.title
      }
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getInsuranceList.fulfilled, (state, action) => {
        state.pending = false
        state.insuranceList = action.payload
      })
      .addCase(getLimitTypes.fulfilled, (state, action) => {
        state.pending = false
        state.limitTypes = action.payload
      })
      .addCase(getLimitDurations.fulfilled, (state, action) => {
        state.pending = false
        state.limitDurations = action.payload
      })
      .addCase(getCreditCardsInfo.fulfilled, (state, action) => {
        state.pending = false
        state.creditCards = action.payload
      })
})

export const { setSelectedInsurance, setSelectedCard} =
  appLimitIncreaseRequestSlice.actions

export default appLimitIncreaseRequestSlice.reducer
