import { baseUrl } from '../../baseUrl'



export const endpoints = {
  customer: `${baseUrl}/customer`,


  
  // ! Endpoints non-secured (START)
  assets: `${baseUrl}/assets`,
  creditCard: `${baseUrl}/credit-card`,
  offerOlaCC: `${baseUrl}/credit-card/offer-ola-cc`,
  assetsByCustomer: `${baseUrl}/customer-assets`,
  tokenType: 'Bearer',
  tokenKeyName: 'access_token_customer',
  storageRefreshTokenKeyName: 'refreshToken_customer',
  // ! non-secured (END)

}