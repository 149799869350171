import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './InsuranceStyle'
import { Checkbox, Typography, useMediaQuery } from '@mui/material'
import Seguro1 from '../../../../assets/CCImages/seguro1.jpg'
import Seguro2 from '../../../../assets/CCImages/seguro2.png'
import { setSelectedInsurance } from '../store'

const Insurance = ({ onSelectedInsurance = () => console.log('Selected') }) => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width:636px)')
  const dispatch = useDispatch()
  const { limits, insuranceList, selectedInsurance } = useSelector(
    (state) => state.limitIncreaseRequest
  )

  React.useEffect(() => {
    if (
      selectedInsurance &&
      selectedInsurance.id &&
      selectedInsurance.title === null &&
      insuranceList &&
      insuranceList.length > 0
    ) {
      const currentIns = insuranceList.find(
        (item) => item.insuranceOptionID === selectedInsurance.id
      )
      if (currentIns) {
        dispatch(setSelectedInsurance(currentIns))
      }
    }
  }, [selectedInsurance, insuranceList])

  const handleOnChange = (option) => {
    onSelectedInsurance && onSelectedInsurance(option)
  }


  return (
    <div>
      <Typography fontSize={16} color={'#666C89'}>
      To protect yourself, please choose one of the options presented below.
      </Typography>
      {insuranceList?.map((item, indx) => (
        <div
          key={indx}
          style={{
            border:
              selectedInsurance?.id === item.insuranceOptionID
                ? '3px solid #2F80ED'
                : '1px solid rgba(0,0,0,0.5)'
          }}
          className={classes.InsuranceContainer}
          onClick={() => dispatch(setSelectedInsurance(item))}
        >
          <img
            src={item.insuranceOptionID === 1 ? Seguro1 : Seguro2}
            alt={`${item.title} representation`}
          />
          <div>
            <Typography color={'#091242'} fontSize={20} fontWeight={600}>
              {item.title}
            </Typography>
            <Typography color={'#666C89'}>{item.description}</Typography>
            <ul>
              <li>
                <Typography color={'#666C89'}>{item.firstBullet}</Typography>
              </li>
              <li>
                <Typography color={'#666C89'}>{item.secondBullet} </Typography>
              </li>
            </ul>
          </div>
          <Checkbox
            onChange={() => handleOnChange( item.insuranceOptionID)}
            checked={selectedInsurance?.id === item.insuranceOptionID}
            style={{
              position: 'absolute',
              top: isSmallScreen ? 5 : 12,
              right: isSmallScreen ? 5 : 12,
              padding: 0
            }}
          />
        </div>
      ))}

      <div
        style={{
          backgroundColor: '#FFD6D6',
          padding: '1rem 2rem',
          borderRadius: '8px',
          margin: '2rem 0',
          textAlign: 'center'
        }}
      >
        <Typography color={'#37393F'}>
          Maximum Coverage: BZ ${limits?.min === 500 ? '30,000' : '30,000'}
        </Typography>
      </div>
    </div>
  )
}

export default Insurance