import ApplyCCImage from '../../../assets/CCImages/apply-cc.png'
import AcceptCCImage from '../../../assets/CCImages/accept-cc.png'
import ContinueCCImage from '../../../assets/CCImages/Continue.png'
import IncreaseCCImage from '../../../assets/CCImages/increase-limit.png'

export const CreditCardInfo = [
]

export const principalOptions = {
  'limit-increase': {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Apply for a credit limit increase',
    bgImage: IncreaseCCImage,
    gradient: '90deg, rgba(86,175,49,1) 25%, rgba(170,238,31,0.50) 100%',
    bgColor: '#5BB133',
    key: 'increase',
    actionKey: 'apply',
    uri: '/limit-increase/personal-information-review',
    show: ({ application }) => application?.qualifyForLimitIncrease
  },
  "apply-for-new-credit-card": {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Apply for a new credit card',
    bgImage: ApplyCCImage,
    gradient: '90deg, rgba(98,144,194,1) 0%, rgba(98,144,194,0.60) 45%',
    bgColor: '#0F5CAC',
    uri: '/available-credit-cards',
    key: 'apply',
    actionKey:'apply',
    show: ({ application }) => !application?.hasApplicationInProgress
  },
  'my-applications': {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Continue my started applications',
    bgImage: ContinueCCImage,
    gradient: '90deg, rgba(86,175,49,1) 0%, rgba(170,238,31,0.50) 45%',
    bgColor: '#5BB133',
    key: 'continue',
    actionKey:'view',
    uri: '/my-applications',
    show: () => true
  },
  'credit-offer': {
    ActiveImg: '',
    InactiveImg: '',
    PrincipalTitle: 'Accept credit card offer',
    bgImage: AcceptCCImage,
    gradient: '90deg, rgba(0,88,255,1) 0%, rgba(0,88,255,0.50) 45%',
    bgColor: '#0A5DFD',
    key: 'accept',
    actionKey:'accept',
    show: (application) => application?.conditions?.canAcceptCreditCardOffer
  }
}
