import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UseJwt from '../../../../auth/jwt/JwtCreditCard';


export const getApplicationsCurrent = createAsyncThunk('appApplicationsHistory/getApplicationsCurrent', async () => {
  const response = await UseJwt.getMyApplications('current')
  return response.data
})

export const getApplicationsComplete = createAsyncThunk('appApplicationsHistory/getApplicationsComplete', async () => {
  const response = await UseJwt.getMyApplications('completed')
  return response.data
})

// GET APPLICATION RESUME
export const getApplicationResume = createAsyncThunk(
  'appApplicationsHistory/getResumeApplication',
  async (id, { rejectWithValue }) => {
    try {
      const response = await UseJwt.getResumeApplication(id);
      console.log(response)
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data);
    }
  }
);
// GET APPLICATION RESUME CO-APPLICANT

export const getScheduleAppointment = createAsyncThunk('appApplicationsHistory/getScheduleAppointment', async () => {
  const response = await UseJwt.getScheduleAppointment()
  return response.data
})

export const getPickupBranch = createAsyncThunk('appApplicationsHistory/getScheduleAppointment', async () => {
  const response = await UseJwt.getPickupBranch()
  return response.data
})

export const updatePickupBranch = createAsyncThunk('appApplicationsHistory/updatePickupBranch', async (data) => {
  const response = await UseJwt.updatePickupBranch(data)
  return response.data
})

export const updateScheduleAppointment = createAsyncThunk('appApplicationsHistory/updateScheduleAppointment', async (data) => {
  const response = await UseJwt.updateScheduleAppointment(data)
  return response.data
})

const initialState = {
  pending: {
    completed: true,
    current: true,
  },
  data: [],
  currentData: [],
  completeData: [],
};

export const appApplicationsHistorySlice = createSlice({
  name: 'appApplicationsHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationsCurrent.pending, (state) => {
        state.pending.current = true
      })
      .addCase(getApplicationsCurrent.fulfilled, (state, action) => {
        state.pending.current = false
        state.currentData = action.payload.applications || []
      })
      .addCase(getApplicationsCurrent.rejected, (state) => {
        state.pending.current = false
      })
      .addCase(getApplicationsComplete.pending, (state) => {
        state.pending.completed = true
      })
      .addCase(getApplicationsComplete.fulfilled, (state, action) => {
        state.pending.completed = false
        state.completeData = action.payload.applications || []
      })
      .addCase(getApplicationsComplete.rejected, (state) => {
        state.pending.completed = false
      })

  },
});

export default appApplicationsHistorySlice.reducer;
