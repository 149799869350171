import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'
import { useStyles } from './PersonalInformationReviewStyle'
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { Field, FormikProvider, useFormik } from 'formik'
import Input from './Input'
import Button from '../../../Components/Button/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { validation } from './validation'
import InputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomizedRadios from '../../../Components/Inputs/Radio'
import { ConfirmOTP } from '../../Components/OtpPopup/OtpPopUp'
import EmailOtpDialog from '../../Components/PopUpEmailConfirmation/EmailOtpDialog'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { pirApplication1Call, uploadSSNDocumentCall } from './store'

const PersonalInformationReviewCC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const ssnFileRef = useRef([])
  const isLimitIncreaseProcess = location.pathname.includes('/limit-increase')


  const isSmallMidScreen = useMediaQuery('(max-width: 950px)')

  const options = [
    { value: 'true', label: 'YES' },
    { value: 'false', label: 'NO' }
  ]
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const [initialFormValues] = useState({
    email: '',
    phone: '',
    hasSsb: false,
    isCurrentPhone: null,
    isCurrentEmail: null,
    newPhoneNumber: '',
    newEmail: '',
    confirmNewEmail: '',
    ssn: '',
    ssnFile: null
  })

  const { data, pending, error } = useSelector(
    (state) => state.personalInformationReview.application
  )

  const [showOtpEmail, setShowOtpEmail] = useState(false)
  const [showOtpPhone, setShowOtpPhone] = useState(false)
  const [haveSocialSecurity, setHaveSocialSecurity] = useState(false)
  const [fileSSNBase64, setFileSSNBase64] = useState('')
  const [canShowFileError, setCanShowFileError] = useState(false)

  const [lastOptionSelection, setLastOptionSelection] = useState({
    phone: null,
    email: null
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: validation,
    onSubmit: (_) => {}
  })

  useEffect(() => {
    dispatch(pirApplication1Call())
  }, [])

  useEffect(() => {
    formik.resetForm({
      values: {
        ...data,
        isCurrentPhone: lastOptionSelection.phone,
        isCurrentEmail: lastOptionSelection.email,
        newPhoneNumber: '',
        newEmail: '',
        confirmNewEmail: '',
        ssn: '',
        ssnFile: null
      }
    })
    setFileSSNBase64('')
  }, [data])

  useEffect(() => {
    if (error && error.statusCode && error.message) {
      toast.error('Error loading data, please try again later.', {
        duration: 7000
      })
      setTimeout(() => {
        toast.error('Try reloading')
      }, 700)
    }
  }, [error])

  const refreshPersonalInformationReview = (refresh) => {
    setLastOptionSelection({
      email: refresh === 'email' ? null : formik.values.isCurrentEmail,
      phone: refresh === 'phone' ? null : formik.values.isCurrentPhone
    })

    dispatch(pirApplication1Call())
  }

  const handleVerifyEmail = () => {
    if (
      !(
        formik.values.newEmail &&
        formik.values.newEmail.length > 0 &&
        emailRegex.test(formik.values.newEmail)
      )
    ) {
      toast.error(
        'Please complete the fields with a valid email before proceeding'
      )
      return
    }

    if (formik.values.newEmail !== formik.values.confirmNewEmail) {
      toast.error('The new email and the confirmation email do not match')
      return
    }

    setShowOtpEmail(true)
  }

  const ssnFileRefClick = () => {
    setCanShowFileError(true)
    ssnFileRef.current.click()
  }

  const updateFile = async (e) => {
    const file = e.target.files[0]
    const proofOfSsn = await convertBase64(file)
    setFileSSNBase64(proofOfSsn)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleSubmit = () => {
    if (pending) return

    const { hasSsb, isCurrentPhone, isCurrentEmail } = formik.values

    if (isCurrentEmail !== 'true')
      return toast.error('Please confirm your current email address')

    if (isCurrentPhone !== 'true')
      return toast.error('Please confirm your current primary contact number')

    if (hasSsb) {
      navigateToNextStep()
      return
    }

    if (haveSocialSecurity === true) {
      navigateToNextStep()
      return
    } else {
      setCanShowFileError(true)
      const cleanSSN = formik.values.ssn.replace(/[\s_]/g, '')
      if (
        formik.values.ssn &&
        cleanSSN.length >= 9
      ) {
        dispatch(
          uploadSSNDocumentCall({
            body: {
              ssnDocumentFile: fileSSNBase64,
              identificationNumber: cleanSSN
            }
          })
        ).then((res) => {
          if (res && res.payload && res.payload.success) {
            navigateToNextStep()
          }
        })
      } else {
        return toast.error('Please provide your valid Social Security')
      }
    }
  }

  const navigateToNextStep = () => {
    if (isLimitIncreaseProcess) {
      navigate('/limit-increase/request')
    } else {
      navigate('/credit-card/credit-limit-request')
    }
  }

  return (
    <section>
      <FormikProvider value={formik} onSubmit={formik.handleSubmit}>
        <Box className={classes.container}>
          <Typography className={classes.Header}>
            Personal Information Review
          </Typography>
          {/* <Typography variant='subtitle1' className={classes.HeaderSubtitle}>
            Please fill out the information
          </Typography> */}
        </Box>
        <Box className={classes.FormContainer}>
          <div
            style={{
              display: 'flex',
              gap: '1.5rem',
              flexWrap: isSmallMidScreen ? 'wrap' : 'nowrap'
            }}
          >
            <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
              <Field name='email'>
                {({ meta }) => (
                  <Input
                    Lable='Confirm this is your current email address'
                    error={meta.touched && meta.error}
                    value={meta.value}
                    readOnly
                  />
                )}
              </Field>

              <Field name='isCurrentEmail'>
                {({ field, meta }) => {
                  return (
                    <>
                      <CustomizedRadios
                        options={options}
                        value={meta.value}
                        onChange={(e) => {
                          formik.setFieldValue(field.name, e.target.value)
                        }}
                      />
                      {meta.value === 'true' && (
                        <Typography
                          variant='subtitle1'
                          className={classes.TextConfirmation}
                        >
                          Thank you for confirming!
                        </Typography>
                      )}
                    </>
                  )
                }}
              </Field>

              {formik.values.isCurrentEmail === 'false' && (
                <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                  mb={0}
                >
                  <Field name='newEmail'>
                    {({ field, meta }) => (
                      <Input
                        style={{ marginBottom: isSmallMidScreen ? 24 : 8 }}
                        LabelTextOther='Please provide us with your new email address'
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name='confirmNewEmail'>
                    {({ field, meta }) => {
                      return (
                        <Input
                          style={{ marginBottom: isSmallMidScreen ? 16 : 5 }}
                          LabelTextOther='Please reconfirm your email address'
                          error={meta.touched && meta.error}
                          {...field}
                        />
                      )
                    }}
                  </Field>

                  <Button
                    Title='Verify'
                    variant='h7'
                    style={{ maxWidth: '256px', width: '100%' }}
                    className={classes.NextButton}
                    BtnVariant='contained'
                    disabled={
                      !(
                        formik.values.newEmail &&
                        formik.values.newEmail.length > 0 &&
                        emailRegex.test(formik.values.newEmail) &&
                        formik.values.newEmail === formik.values.confirmNewEmail
                      )
                    }
                    onClick={() => handleVerifyEmail()}
                  />

                  <Typography
                    variant='subtitle1'
                    className={classes.TextConfirmationError}
                  >
                    Please verify your new emial before proceeding by clicking
                    on the 'verify' button
                  </Typography>
                </Stack>
              )}
            </div>

            <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
              <Field name='phone'>
                {({ meta }) => (
                  <Input
                    Lable='Confirm this is your primary contact number'
                    error={meta.touched && meta.error}
                    // LabelTextOther='(Is this your current primary contact number?)'
                    value={meta.value}
                    readOnly
                  />
                )}
              </Field>

              <Field name='isCurrentPhone'>
                {({ field, meta }) => (
                  <>
                    <CustomizedRadios
                      options={options}
                      value={meta.value}
                      onChange={(e) => {
                        formik.setFieldValue(field.name, e.target.value)
                      }}
                    />
                    {meta.value === 'true' && (
                      <Typography
                        variant='subtitle1'
                        className={classes.TextConfirmation}
                      >
                        Thank you for confirming!
                      </Typography>
                    )}
                  </>
                )}
              </Field>

              {formik.values.isCurrentPhone === 'false' && (
                <>
                  <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='flex-end'
                    flexWrap={isSmallMidScreen ? 'wrap' : 'nowrap'}
                    gap={2}
                  >
                    <Field name='newPhoneNumber'>
                      {({ field, form, meta }) => (
                        <InputMask
                          {...field}
                          mask='501-699-9999'
                          alwaysShowMask={false}
                          disabled={false}
                          value={meta.phone}
                          onChange={(e) =>
                            form.setFieldValue('newPhoneNumber', e.target.value)
                          }
                        >
                          {(inputProps) => (
                            <Input
                              LabelTextOther='Please provide us with your new cell phone number'
                              error={meta.touched && meta.error}
                              {...inputProps}
                              type='tel'
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>

                    <Stack justifyContent='center' alignItems='center'>
                      <Button
                        Title='Verify'
                        variant='h7'
                        style={{ minWidth: '232px', maxWidth: '256px' }}
                        className={classes.NextButton}
                        BtnVariant='contained'
                        disabled={formik.values.newPhoneNumber.length === 0}
                        onClick={() => setShowOtpPhone(true)}
                      />
                    </Stack>
                  </Stack>
                  <Typography
                    variant='subtitle1'
                    className={classes.TextConfirmationError}
                  >
                    Please verify your new cell number before proceeding by
                    clicking on the 'verify' button
                  </Typography>
                </>
              )}
            </div>
          </div>

          {data.hasSsb === false && (
            <div>
              <Divider className={classes.Divider} />

              <Typography
                variant='subtitle1'
                flexDirection={'column'}
                className={classes.Text}
              >
                It seems that we don’t have your social security number. Please
                provide your valid Social Security number below.
              </Typography>

              {haveSocialSecurity === false && (
                <Stack
                  direction='row'
                  flexWrap={isSmallMidScreen ? 'wrap' : 'nowrap'}
                  alignItems='center'
                  gap={2}
                  mb={4}
                >
                  <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                    <Field name='ssn'>
                      {({ field, meta }) => (
                        <>
                          <InputMask
                            mask='999 999 999'
                            alwaysShowMask={false}
                            name='ssn'
                            {...field}
                          >
                            {(inputProps) => (
                              <Input
                                Lable='SOCIAL SECURITY NUMBER*'
                                {...inputProps}
                                type='text'
                              />
                            )}
                          </InputMask>
                          {meta.error && (
                            <p className={classes.fileText}>{meta.error}</p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>

                  <div style={{ width: isSmallMidScreen ? '100%' : '50%' }}>
                    <Typography
                      style={{
                        color: '#868686',
                        fontSize: '11px',
                        fontWeight: '600',
                        lineHeight: '17px',
                        letterSpacing: '0.2em'
                      }}
                    >
                      SOCIAL SECURITY CARD
                    </Typography>

                    <Button
                      style={{ marginTop: '1.35rem' }}
                      Title='Upload Files'
                      variant='h7'
                      className={classes.UploadFiles}
                      endIcon={<AttachFileIcon />}
                      onClick={ssnFileRefClick}
                    />
                    <Field name='ssnFile'>
                      {({ field, meta }) => (
                        <>
                          <input
                            {...field}
                            type='file'
                            accept='application/pdf,image/*'
                            onChange={(event) => {
                              formik.setFieldValue(
                                field.name,
                                event.currentTarget.files[0]
                              )
                              updateFile(event)
                            }}
                            className={classes.MonthlyCreditInput}
                            value=''
                            style={{ display: 'none' }}
                            ref={ssnFileRef}
                          />

                          {/* <p className={classes.fileText}>
                            Only PDF and PNG files are permitted with a maximum
                            of 2MB
                          </p> */}

                          {meta?.value?.name && (
                            <p className={classes.uploadFileName}>
                              {`${
                                meta.value.name.length > 24
                                  ? `${String(meta.value.name).substring(
                                      0,
                                      24
                                    )}...`
                                  : meta.value.name
                              }`}

                              <span
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: 4
                                }}
                              >
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                    setFileSSNBase64('')
                                  }}
                                />
                              </span>
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </Stack>
              )}

              {!(
                formik.values.ssn &&
                formik.values.ssn.length >= 9
              ) && (
                <>
                  <Button
                    Title={
                      haveSocialSecurity
                        ? 'I have a Social Security Card'
                        : `I Don't have a Social Security Card`
                    }
                    variant='h7'
                    className={
                      haveSocialSecurity === false
                        ? classes.noSSNBtn
                        : classes.noSSNBtnNo
                    }
                    BtnVariant='contained'
                    onClick={() => {
                      formik.setFieldValue('ssn', '')
                      formik.setFieldValue('ssnFile', null)
                      setFileSSNBase64('')
                      setHaveSocialSecurity(!haveSocialSecurity)
                    }}
                  />

                  {haveSocialSecurity === false && (
                    <Typography
                      variant='subtitle1'
                      display='block'
                      align='center'
                      className={classes.TextConfirmationError}
                    >
                      SOCIAL SECURITY NUMBER is required!
                    </Typography>
                  )}
                </>
              )}
            </div>
          )}

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            justifyContent='center'
            alignItems='center'
            mt={5}
            mb={2}
          >
            <Button
              Title={pending ? '...' : 'CONTINUE'}
              variant='h7'
              className={classes.NextButton}
              BtnVariant='contained'
              disabled={
                formik.values.isCurrentEmail === null ||
                formik.values.isCurrentPhone === null ||
                pending
              }
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </FormikProvider>

      {/* pop-ups and dialgs */}
      <Toaster />

      {/* <PopupAlertError setconfirmEmail={setconfirmEmail} /> */}

      <EmailOtpDialog
        open={showOtpEmail}
        setOpenDialog={setShowOtpEmail}
        newEmail={formik.values.newEmail}
        refreshData={() => {
          refreshPersonalInformationReview('email')
        }}
      />

      <ConfirmOTP
        open={showOtpPhone}
        setOpenDialog={setShowOtpPhone}
        newPhoneNumber={formik.values.newPhoneNumber}
        refreshData={() => {
          refreshPersonalInformationReview('phone')
        }}
      />
    </section>
  )
}

export default PersonalInformationReviewCC
