import React, { useEffect } from 'react'
import Button from '../../Components/Button/Button'
import { useStyles } from './ProofOfIncomeStyle'
import { Box, Stack } from '@mui/material'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import Alert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import FILEJOBLETTER from '../../../assets/pdf/Employment Verification Letter.pdf'
import { Download } from '@mui/icons-material'

import { useMovil } from '../../../hooks/useMovil'
import AlertInfo from '../../Components/Alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  bankCatalogCall,
  getProofOfIncomeInfoCall,
  jobLetterCatalogCall,
  postProofOfIncomeCall,
  salaryCatalogCall,
  setErrors,
  updateProofOfIncomeCall
} from './store'
// import clsx from 'clsx'
import { toast, Toaster } from 'react-hot-toast'
import SalaryPayment from './components/SalaryPayment'
import InstitutionFinancial from './components/InstitutionFinancial'
import JobLetter from './components/JobLetter'
import UploadFile from './components/UploadFile'
import EmailConfirmation from './components/EmailConfirmation'
import {
  deleteDocument,
  updateApplicationStep,
  uploadDocument
} from '../../context/global-application'
import { APPLICATION_STEP } from '../../options'
import { JOB_LETTERS, proofOfIncome, SALARY_PAYMENTS } from './dispatches'
import Spinner from '../../../Components/BackDrop/Spinner'

const ProofOfIncomeCC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formData, nextAction, pending, data } = useSelector(
    (state) => state.ccProofOfIncome
  )

  const classes = useStyles()

  const { message, hasDownload } = useMovil('employementverification')

  const downloadJobLetter = () => {
    const link = document.createElement('a')
    link.download = `${'Employment Verification Letter' || ''}`
    link.href = FILEJOBLETTER
    link.click()
  }

  const saveDocument = (data, fileData) => {
    if (!fileData.file) return

    const response = data.payload
    const sendData = {
      moduleId: response.id,
      module: response.module,
      documentType: response.documentKey,
      documentName: fileData.file.name,
      documentFile: fileData.file.base64
    }

    dispatch(uploadDocument(sendData)).finally(() =>
      dispatch(getProofOfIncomeInfoCall())
    )
  }

  const submitData = async (event) => {
    event.preventDefault()
    const salaryId = SALARY_PAYMENTS[formData.salaryId]
    const jobLetterId =
      SALARY_PAYMENTS[formData.salaryId] !== 'selfEmployed'
        ? JOB_LETTERS[formData.jobLetterId]
        : 'only'

    if (!salaryId) {
      dispatch(setErrors({ salaryId: 'Please select a salary payment' }))
    }
    if (!jobLetterId) {
      dispatch(setErrors({ jobLetterId: 'Please select a job letter' }))
    }
    if (!salaryId || !jobLetterId) return

    const validator = proofOfIncome[salaryId][jobLetterId].validator(formData)
    dispatch(setErrors(validator))
    if (Object.values(validator).some((v) => v)) return
    else dispatch(setErrors({ salaryId: null, jobLetterId: null, bankId: null, employerEmail: null, file: null }))

    const adapter = proofOfIncome[salaryId][jobLetterId].adapter(formData)
    if (nextAction === 'CREATE') {
     await dispatch(postProofOfIncomeCall(adapter)).then((result) => {
        saveDocument(result, adapter)
      })
    }
    if (nextAction === 'UPDATE') {
      const sendData = {
        ...adapter,
      }
      if (sendData.file) {
        sendData.documentFile = adapter.file.base64
        sendData.documentName = adapter.file.name
        // sendData.documentType = 'proofOfIncome'
        // sendData.moduleId = formData.id
        // sendData.module = data.module
        delete sendData.file
      }
      await dispatch(updateProofOfIncomeCall(sendData))
    }
    navigate(APPLICATION_STEP['upload-documents-cc'].path)
  }

  useEffect(() => {
    window.scroll(0, 0)

    dispatch(salaryCatalogCall())
    dispatch(jobLetterCatalogCall())
    dispatch(getProofOfIncomeInfoCall())
    dispatch(bankCatalogCall())
    dispatch(updateApplicationStep(APPLICATION_STEP['proof-of-income-cc'].key))
  }, [])

  const handleDeleteFile = () => {
    dispatch(deleteDocument({ id: formData.file.id, module: data.module }))
  }

  return (
    <Box>
      <Spinner open={pending} />
      <Box className={classes.ProofOfIncomeContainer}>
        <div className={classes.ButtonTitle}>Proof Of Income</div>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          alignItems='end'
          gap={3}
          mb={10}
        >
          <SalaryPayment />

          {formData.salaryId === 2 && <InstitutionFinancial />}
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          gap={3}
          mb={10}
        >
          {formData.salaryId !== 4 && (
            <React.Fragment>
              <Stack width={{ xs: '100%', sm: '50%' }}>
                <JobLetter />
              </Stack>

              {formData?.jobLetterId === 3 && (
                <Stack width={{ xs: '100%', sm: '50%' }}>
                  {hasDownload && (
                    <Button
                      Title='Download Job Letter'
                      className={classes.UploadFiles}
                      endIcon={<Download />}
                      onClick={downloadJobLetter}
                    />
                  )}
                  {!hasDownload && <AlertInfo content={message} />}
                </Stack>
              )}
            </React.Fragment>
          )}

          {[1, 3, 4].includes(formData?.jobLetterId) &&
            formData?.salaryId !== 4 && (
              <Stack width={{ xs: '100%', sm: '50%' }}>
                <UploadFile onDelete={handleDeleteFile}/>
              </Stack>
            )}
        </Stack>

        {formData.jobLetterId === 2 && formData.salaryId !== 4 && (
          <EmailConfirmation />
        )}

        {formData?.jobLetterId &&
          formData?.jobLetterId !== 1 &&
          formData?.salaryId !== 4 && (
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
              alignItems='end'
              style={{ margin: '35px 0' }}
            >
              <Alert
                icon={false}
                sx={{ backgroundColor: '#F4EBFE', padding: '20px' }}
              >
                <strong>
                  Dear {localStorage.getItem('name') || 'Client'},
                </strong>{' '}
                <br />
                Please note that your loan cannot be approved without a valid
                employment letter (issued within the last 6 months). Kindly
                request your Employer to issue the letter promptly; thereafter,
                please submit this document via Belize Bank’s online loan
                application.
              </Alert>
            </Stack>
          )}
      </Box>

      <ArrowButton
        back={'/credit-card/monthly-expenses'}
        onClick={(event) => {
          submitData(event)
        }}
      />

      <Toaster />
    </Box>
  )
}

export default ProofOfIncomeCC
