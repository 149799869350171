import React from 'react'
import CurrentApplication from './CurrentApplication'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Stack,
  Typography,
  Button
} from '@mui/material'
import bgImage from '../../../assets/CCImages/bg-title-page.png'
import { getApplicationsComplete, getApplicationsCurrent } from './store/index.js'
import { useStyles } from './CardCurrentApplicationStyle'
import CompletedApplication from './CompletedApplication.js'

const MyApplications = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    dispatch(getApplicationsCurrent())
    dispatch(getApplicationsComplete())
  }, [dispatch])

  return (
    <React.Fragment>
      <Container style={{ marginBottom: '10rem' }}>
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            height: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2rem',
            width: '100%'
          }}
        >
          <Typography color='#fff' fontSize={36}>
            My Credit Card Applications
          </Typography>
        </div>

        <div
          style={{
            background: '#fff',
            maxWidth: '100%',
            boxShadow: '3px 4px 8px rgba(0,0,0,0.25)',
            display: 'flex',
            justifyContent: 'space-between',
            overflowX: 'auto',
            padding: '20px 15px',
            minHeight: '80vh'
          }}
        >
          <Stack spacing={2} direction='column' width='100%'>
            <CompletedApplication />
            <CurrentApplication />
          </Stack>
        </div>

        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <Button
            className={classes.BtnConfirm}
            onClick={() => navigate('/select-options')}
          >
            Back to Main Screen
          </Button>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default MyApplications
