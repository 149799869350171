import { Container, Typography } from '@mui/material'
import React from 'react'
import bgImage from '../../../assets/CCImages/bg-title-page.png';

export const CreditLimitIncrease = () => {
  return (
    <Container style={{ marginBottom: '10rem' }}>
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            height: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2rem',
            width: '100%',
          }}
        >
          <Typography color="#fff" fontSize={36}>
            Credit Limit Increase Request
          </Typography>
        </div>
        <div
          style={{
            background: '#fff',
            maxWidth: '100%',
            boxShadow: '3px 4px 8px rgba(0,0,0,0.25)',
            display: 'flex',
            justifyContent: 'space-between',
            overflowX: 'auto',
            padding: '20px 15px',
            minHeight: '80vh',
          }}
        >
            
        </div>
      </Container>
  )
}
