import { defaultAdapter } from '../adapters/default.adapter'
import { jobLetterAdapter } from '../adapters/job-letter'

export const UPLOAD_DOCUMENTS = {
  'job-letter': {
    internalKey: 'job-letter',
    adapter: jobLetterAdapter(),
  },
  'default': {
    internalKey: 'default',
    adapter: defaultAdapter(),
  },
}