import moment from 'moment-timezone'
import * as Yup from 'yup'

export const getRandomTime = () => {
  const startHour = 8 // 8 AM
  const endHour = 14 // 3 PM
  const randomHour = Math.floor(Math.random() * (endHour - startHour)) + startHour
  const randomMinute = Math.random() < 0.5 ? 0 : 30
  return moment.tz('America/Belize')
    .add(1, 'days')
    .set({ hour: randomHour, minute: randomMinute })
    .toISOString()
}

export const validationSchema = Yup.object({
  branchId: Yup.string().required('Branch is required'),
  appointmentDate: Yup.date().required('Appointment date is required'),
  appointmentTime: Yup.string().required('Appointment time is required'),
  branchPickupId: Yup.string().required('Branch pickup is required')
})