import React from 'react'
import ModalContainer from '../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import { Container, Typography, Box } from '@mui/material'
import WarningImage from '../../../assets/CCImages/warning-icon.png' // Corrige el nombre del archivo si es necesario
import Button from '../../Components/Button/Button'

const RejectedModal = ({ open, onClose, onSubmit }) => {
  return (
    <React.Fragment>
      <ModalContainer open={open} width={432} onClose={onClose}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '24px'
          }}
        >
          <Box sx={{ mb: 2 }}>
            <img
              src={WarningImage}
              alt='Warning'
              style={{ width: '90px', height: '90px' }}
            />
          </Box>

          <Typography variant='body1' sx={{ mb: 2 }}>
            To avoid any disruptions, please process your payments by the
            payment due date. Kindly note that you can choose to set up your
            standing order at any point in time.
          </Typography>

          <Button
            onClick={() => {
              onClose()
              onSubmit()
            }}
            Title='OKAY'
            variant='contained'
            style={{
              width: '220px',
              backgroundColor: '#005FAA',
              color: 'white',
              borderRadius: '2rem'
            }}
          />
        </Container>
      </ModalContainer>
    </React.Fragment>
  )
}

export default RejectedModal
