// import React, { useState, useEffect } from 'react';
// import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, Button, Checkbox } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useStyles } from './SignDocumentsPreviwStyle';

// const SignDocumentPreview = ({ applications, onAllDownload }) => {
//   const classes = useStyles();
//   const pending = false;

//   const [downloadedDocs, setDownloadedDocs] = useState([]);
//   const [checkedDocs, setCheckedDocs] = useState({}); // Track checkbox state for each document

//   const collateralDocuments = applications.filter(app => app.documentType === 'Collateral Document');
//   const insuranceAgreements = applications.filter(app => app.documentType === 'Insurance Agreement');
//   const cardholderAgreements = applications.filter(app => app.documentType === 'Cardholder Agreement');

//   const allDocuments = [...collateralDocuments, ...insuranceAgreements, ...cardholderAgreements];

//   const handleCheckboxChange = (docId) => {
//     setCheckedDocs((prev) => ({
//       ...prev,
//       [docId]: !prev[docId],
//     }));
//   };

//   const handleDownload = (docId) => {
//     if (checkedDocs[docId] && !downloadedDocs.includes(docId)) {
//       setDownloadedDocs((prev) => [...prev, docId]);
//     }
//   };

//   useEffect(() => {
//     if (downloadedDocs.length === allDocuments.length && allDocuments.length > 0) {
//       onAllDownload();
//     }
//   }, [downloadedDocs, allDocuments, onAllDownload]);

//   const renderDocuments = (documents) =>
//     documents.map((doc) => (
//       <div
//         key={doc.id}
//         style={{
//           background: "#F4EBFE",
//           width: "100%",
//         }}
//       >
//         <div style={{ width: "90%", margin: "40px auto", display: "flex", flexDirection: "column" }}>
//         <Typography>
//           I warrant and confirm that all information given by me to The Belize Bank Limited (the “Bank”) in relation to my loan
//           application including, but not limited to the information contained in the Personal Lending Disclosure Summary, is 
//           true and correct. In the event that I make any future loan application(s), I undertake to update all previously provided 
//           information or to provide the Bank with true and accurate original information to facilitate the completion of the
//           application. I understand that all information given in support of this loan application and any subsequent loan 
//           application(s) will be used by the Bank to assess my credit worthiness and to determine whether to grant any credit 
//           facilities to me. I therefore confirm that I have not provided any false or misleading information and that I have not 
//           withheld and will not withhold any information that might negatively affect the Bank’s decision to grant any credit 
//           facility to me.
//           <br/>
//           <br/>
//           I agree that if at any time I provide or have provided the Bank with false or misleading information or withhold or 
//           have withheld any information, the Bank will have the right to reject any application or terminate my credit facility.
//           <br/>
//           <br/>
//           I authorize and grant permission to the Bank to request, to obtain, to receive, to collect and to store any information 
//           it may require on me for the purpose of granting any credit facility to me or for complying with the laws of Belize. I 
//           further authorize the Bank to disclose any such information, which it may have in its possession. I acknowledge and 
//           agree that the authority to disclose my information will include, but not be limited to disclosure to any of the Bank’s 
//           employees, agents, third parties, assignees, other financial institutions, my current and former employers, and any 
//           other persons, natural or legal, with whom I have financial dealings from time to time or as required by law.
//           <br/>
//           <br/>
//           I agree that I will not, without reasonable excuse or undue delay, refuse to provide the Bank with updated 
//           information required but the Bank from time to time.
//           <br/>
//           <br/>
//           I understand that the execution of this Declaration does not guarantee that my loan application will be approved by
//           the Bank or that the Bank will grant any credit facility to me and that any credit extended or funds advanced to me, 
//           now or in the future, will be subject to the terms and conditions of the relevant loan agreement with the Bank.

//         </Typography>
          
//           <Button
//             href={`${doc.documentFile}`}
//             download={`${doc.documentType}.pdf`}
//             style={{ background: 'none', margin: '0', padding: 0, border: "none" }}
//             onClick={() => handleDownload(doc.id)}
//             disabled={!checkedDocs[doc.id]} // Disable if checkbox isn't checked
//           >
//             {doc.documentType}.pdf
//           </Button>
//           <div style={{ display: "flex", alignItems: "center" }}>
//             <Checkbox
//               checked={checkedDocs[doc.id] || false}
//               onChange={() => handleCheckboxChange(doc.id)}
//               disabled={!downloadedDocs.includes(doc.id)}
//             />
//             <Typography style={{ marginBottom: 0 }}>Documents were read and understood</Typography>
//           </div>
//         </div>
//       </div>
//     ));

//   return (
//     <Stack spacing={{ xs: 4, md: 2 }} direction="column" mb={3} width="100%">
//       {downloadedDocs.length === allDocuments.length && (
//         <Typography variant="h6" style={{ color: 'green', textAlign: 'center', marginBottom: '1rem' }}>
//           All documents have been downloaded!
//         </Typography>
//       )}

//       <Accordion elevation={0} className={classes.Accordian}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography variant="body2" className={classes.AccordianHeader}>
//             Collateral Documents
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
//           {pending ? (
//             <Typography variant="body2">Loading...</Typography>
//           ) : collateralDocuments.length > 0 ? (
//             renderDocuments(collateralDocuments)
//           ) : (
//             <Typography variant="body2">No collateral documents found.</Typography>
//           )}
//         </AccordionDetails>
//       </Accordion>

//       {/* Repeat for other document categories */}
//       {/* Insurance Agreements */}
//       <Accordion elevation={0} className={classes.Accordian}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography variant="body2" className={classes.AccordianHeader}>
//             Insurance Agreements
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
//           {pending ? (
//             <Typography variant="body2">Loading...</Typography>
//           ) : insuranceAgreements.length > 0 ? (
//             renderDocuments(insuranceAgreements)
//           ) : (
//             <Typography variant="body2">No insurance agreements found.</Typography>
//           )}
//         </AccordionDetails>
//       </Accordion>

//       {/* Cardholder Agreements */}
//       <Accordion elevation={0} className={classes.Accordian}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography variant="body2" className={classes.AccordianHeader}>
//             Cardholder Agreements
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
//           {pending ? (
//             <Typography variant="body2">Loading...</Typography>
//           ) : cardholderAgreements.length > 0 ? (
//             renderDocuments(cardholderAgreements)
//           ) : (
//             <Typography variant="body2">No cardholder agreements found.</Typography>
//           )}
//         </AccordionDetails>
//       </Accordion>
//     </Stack>
//   );
// };

// export default SignDocumentPreview;



import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, Button, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from './SignDocumentsPreviwStyle';

const SignDocumentPreview = ({ applications, onAllDownload }) => {
  const classes = useStyles();
  const pending = false;

  const [downloadedDocs, setDownloadedDocs] = useState([]);
  const [checkedDocs, setCheckedDocs] = useState({}); // Track checkbox state for each document

  const collateralDocuments = applications.filter(app => app.documentType === 'Collateral Document');
  const insuranceAgreements = applications.filter(app => app.documentType === 'Insurance Agreement');
  const cardholderAgreements = applications.filter(app => app.documentType === 'Cardholder Agreement');

  const allDocuments = [...collateralDocuments, ...insuranceAgreements, ...cardholderAgreements];

  const handleCheckboxChange = (docId) => {
    setCheckedDocs((prev) => ({
      ...prev,
      [docId]: !prev[docId],
    }));
  };

  const handleDownload = (docId) => {
    if (!downloadedDocs.includes(docId)) {
      setDownloadedDocs((prev) => [...prev, docId]);
    }
  };

  useEffect(() => {
    if (downloadedDocs.length === allDocuments.length && allDocuments.length > 0) {
      onAllDownload();
    }
  }, [downloadedDocs, allDocuments, onAllDownload]);

  const renderDocuments = (documents) =>
    documents.map((doc) => (
      <div
        key={doc.id}
        style={{
          background: "#F4EBFE",
          width: "100%",
        }}
      >
        <div style={{ width: "90%", margin: "40px auto", display: "flex", flexDirection: "column" }}>
        <Typography>
          I warrant and confirm that all information given by me to The Belize Bank Limited (the “Bank”) in relation to my loan
          application including, but not limited to the information contained in the Personal Lending Disclosure Summary, is 
          true and correct. In the event that I make any future loan application(s), I undertake to update all previously provided 
          information or to provide the Bank with true and accurate original information to facilitate the completion of the
          application. I understand that all information given in support of this loan application and any subsequent loan 
          application(s) will be used by the Bank to assess my credit worthiness and to determine whether to grant any credit 
          facilities to me. I therefore confirm that I have not provided any false or misleading information and that I have not 
          withheld and will not withhold any information that might negatively affect the Bank’s decision to grant any credit 
          facility to me.
          <br/>
          <br/>
          I agree that if at any time I provide or have provided the Bank with false or misleading information or withhold or 
          have withheld any information, the Bank will have the right to reject any application or terminate my credit facility.
          <br/>
          <br/>
          I authorize and grant permission to the Bank to request, to obtain, to receive, to collect and to store any information 
          it may require on me for the purpose of granting any credit facility to me or for complying with the laws of Belize. I 
          further authorize the Bank to disclose any such information, which it may have in its possession. I acknowledge and 
          agree that the authority to disclose my information will include, but not be limited to disclosure to any of the Bank’s 
          employees, agents, third parties, assignees, other financial institutions, my current and former employers, and any 
          other persons, natural or legal, with whom I have financial dealings from time to time or as required by law.
          <br/>
          <br/>
          I agree that I will not, without reasonable excuse or undue delay, refuse to provide the Bank with updated 
          information required but the Bank from time to time.
          <br/>
          <br/>
          I understand that the execution of this Declaration does not guarantee that my loan application will be approved by
          the Bank or that the Bank will grant any credit facility to me and that any credit extended or funds advanced to me, 
          now or in the future, will be subject to the terms and conditions of the relevant loan agreement with the Bank.

        </Typography>
          
          <Button
            href={`${doc.documentFile}`}
            download={`${doc.documentType}.pdf`}
            style={{ background: 'none', margin: '0', padding: 0, border: "none" }}
            onClick={() => handleDownload(doc.id)}
          >
            {doc.documentType}.pdf
          </Button>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={checkedDocs[doc.id] || false}
              onChange={() => handleCheckboxChange(doc.id)}
              disabled={!downloadedDocs.includes(doc.id)} // Disable until the document is downloaded
            />
            <Typography style={{ marginBottom: 0 }}>Documents were read and understood</Typography>
          </div>
        </div>
      </div>
    ));

  return (
    <Stack spacing={{ xs: 4, md: 2 }} direction="column" mb={3} width="100%">
      {downloadedDocs.length === allDocuments.length && (
        <Typography variant="h6" style={{ color: 'green', textAlign: 'center', marginBottom: '1rem' }}>
          All documents have been downloaded!
        </Typography>
      )}

      <Accordion elevation={0} className={classes.Accordian}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" className={classes.AccordianHeader}>
            Collateral Documents
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {pending ? (
            <Typography variant="body2">Loading...</Typography>
          ) : collateralDocuments.length > 0 ? (
            renderDocuments(collateralDocuments)
          ) : (
            <Typography variant="body2">No collateral documents found.</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Repeat for other document categories */}
      {/* Insurance Agreements */}
      <Accordion elevation={0} className={classes.Accordian}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" className={classes.AccordianHeader}>
            Insurance Agreements
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {pending ? (
            <Typography variant="body2">Loading...</Typography>
          ) : insuranceAgreements.length > 0 ? (
            renderDocuments(insuranceAgreements)
          ) : (
            <Typography variant="body2">No insurance agreements found.</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Cardholder Agreements */}
      <Accordion elevation={0} className={classes.Accordian}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" className={classes.AccordianHeader}>
            Cardholder Agreements
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {pending ? (
            <Typography variant="body2">Loading...</Typography>
          ) : cardholderAgreements.length > 0 ? (
            renderDocuments(cardholderAgreements)
          ) : (
            <Typography variant="body2">No cardholder agreements found.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default SignDocumentPreview;
