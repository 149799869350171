import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  createFilterOptions,
  InputLabel,
  Stack
} from '@mui/material'
import { useStyles } from '../ProofOfIncomeStyle'
import clsx from 'clsx'
import { setFormData } from '../store'

const SalaryPayment = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [touched, setTouched] = React.useState(false)
  const { catalogs, errors, formData } = useSelector((state) => state.ccProofOfIncome)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <InputLabel shrink className={classes.SelectLable}>
        Salary Payment
      </InputLabel>
      <Autocomplete
        fullWidth
        disablePortal
        options={catalogs.salaryCatalog} // [{ id: 1, name: 'Monthly' }, { id: 2, name: 'Weekly' }]
        filterOptions={createFilterOptions({
          stringify: (option) => option.name
        })}
        getOptionLabel={(option) => option.name}
        value={catalogs.salaryCatalog.find((item) => item.id === formData?.salaryId) || null}
        onChange={(event, newValue) => {
          event.preventDefault()
          if (newValue) {
            dispatch(setFormData({ salaryId: newValue.id }))
          }
        }}
        className={clsx(classes.Select)}
        classes={{ paper: classes.paper }}
        onBlur={() => setTouched(true)}
        renderInput={(params) => (
          <TextField {...params} placeholder='Please select one option' />
        )}
      />
      {errors?.salaryId && (
        <p className={classes.errorMessage}>{errors.salaryId}</p>
      )}
    </div>
  )
}

export default SalaryPayment
