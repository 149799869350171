// Import necessary modules
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UseJwt from '../../../../auth/jwt/JwtCreditCard';

// Existing GET SIGN DOCUMENTS
export const getSignDocuments = createAsyncThunk(
  'appSignDocuments/getSignDocuments',
  async () => {
    const response = await UseJwt.getSignDocuments();
    return response.data;
  }
);

// Existing SIGN DOCUMENTS
export const postSignDocuments = createAsyncThunk(
  'appSignDocuments/signDocuments',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UseJwt.postSignDocuments(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'An error occurred while signing documents'
      );
    }
  }
);

// Existing GET BRANCHES
export const getSignBranches = createAsyncThunk(
  'appSignDocuments/getBranches',
  async () => {
    const response = await UseJwt.getSignBranches();
    return response.data;
  }
);

// New PICKUP BRANCH
export const postPickupBranch = createAsyncThunk(
  'appSignDocuments/postPickupBranch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UseJwt.postPickupBranch(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'An error occurred while setting pickup branch'
      );
    }
  }
);

// New SCHEDULE APPOINTMENT
export const postSigningAppointment = createAsyncThunk(
  'appSignDocuments/postSigningAppointment',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UseJwt.postSigningAppointment(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'An error occurred while scheduling the appointment'
      );
    }
  }
);

export const sendScheduledAppointment = createAsyncThunk('appSignDocuments/sendScheduledAppointment', async (payload) => {
  const response = await UseJwt.sendScheduleAppointment(payload)
  return response.data
})

// Initial state
const initialState = {
  pending: false,
  signing: false,
  success: false,
  error: null,
  documents: null,
  branches: null,
  pickupBranch: null, 
  appointment: null, 
};

export const appSignDocumentsSlice = createSlice({
  name: 'appSignDocuments',
  initialState,
  reducers: {
    resetSignDocumentsState: (state) => {
      state.pending = false;
      state.signing = false;
      state.success = false;
      state.error = null;
      state.documents = null;
      state.branches = null;
      state.pickupBranch = null;
      state.appointment = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle GET SIGN DOCUMENTS
      .addCase(getSignDocuments.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSignDocuments.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.documents = action.payload;
      })
      .addCase(getSignDocuments.rejected, (state, action) => {
        state.pending = false;
        state.error =
          action.error?.message || 'An error occurred while fetching documents';
      })
      // Handle SIGN DOCUMENTS
      .addCase(postSignDocuments.pending, (state) => {
        state.signing = true;
        state.success = false;
        state.error = null;
      })
      .addCase(postSignDocuments.fulfilled, (state, action) => {
        state.signing = false;
        state.success = true;
        state.documents = action.payload;
      })
      .addCase(postSignDocuments.rejected, (state, action) => {
        state.signing = false;
        state.error =
          action.payload || 'An error occurred while signing documents';
      })
      // Handle GET BRANCHES
      .addCase(getSignBranches.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSignBranches.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.branches = action.payload;
      })
      .addCase(getSignBranches.rejected, (state, action) => {
        state.pending = false;
        state.error =
          action.error?.message || 'An error occurred while fetching branches';
      })
      // Handle POST PICKUP BRANCH
      .addCase(postPickupBranch.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(postPickupBranch.fulfilled, (state, action) => {
        state.pending = false;
        state.pickupBranch = action.payload;
      })
      .addCase(postPickupBranch.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || 'An error occurred while setting pickup branch';
      })
      // Handle POST SIGNING APPOINTMENT
      .addCase(postSigningAppointment.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(postSigningAppointment.fulfilled, (state, action) => {
        state.pending = false;
        state.appointment = action.payload;
      })
      .addCase(postSigningAppointment.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || 'An error occurred while scheduling the appointment';
      });
  },
});

export const { resetSignDocumentsState } = appSignDocumentsSlice.actions;

export default appSignDocumentsSlice.reducer;
