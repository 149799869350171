import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../core/jwt/jwtService'

const olaService = new UseJwt({})


export const getOfferOlaCC = createAsyncThunk('appThankYou/getHasCreditApplication', async () => {
  const response = await olaService.getOfferOlaCC()
  return response.data
})

export const acceptOfferOlaCC = createAsyncThunk('appThankYou/acceptOfferOlaCC', async (_, { rejectWithValue }) => {
  try {
    const response = await olaService.acceptOfferOlaCC()
    console.log('response >>>', response)
    return response?.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

const initialState = {
  pending: true,
  creditApplicationData: {
    type: "", // '',
    title: "", // '',
    description: ""
  }
}
export const appThankYouSlice = createSlice({
  name: 'appThankYou',
  initialState,
  extraReducers: {
    [getOfferOlaCC.fulfilled]: (state, action) => {
      state.creditApplicationData = action.payload
      state.pending = false
    },
    [getOfferOlaCC.pending]: (state) => {
      state.pending = true
    },
    [getOfferOlaCC.rejected]: (state) => {
      state.pending = false
    }
  }
})

export default appThankYouSlice.reducer