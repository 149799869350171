import moment from 'moment'

export const hoursInterval = [
  `${moment().format('YYYY-MM-DD')}T08:00:00`,
  `${moment().format('YYYY-MM-DD')}T09:00:00`,
  `${moment().format('YYYY-MM-DD')}T10:00:00`,
  `${moment().format('YYYY-MM-DD')}T11:00:00`,
  `${moment().format('YYYY-MM-DD')}T12:00:00`,
  `${moment().format('YYYY-MM-DD')}T13:00:00`,
  `${moment().format('YYYY-MM-DD')}T14:30:00`
  // `${moment().format('YYYY-MM-DD')}T15:00:00`,
]