import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import LandLoanData from '../../Components/Forms/ScheduleAppointment/LandLoanData'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { hasSecureLandDocs } from '../../Store/context/postLandDocs'
import { Interceptor } from '../../Utils/Interceptor'
import { useStyles } from './styles'
import Actions from '../../Store/Types'
// import { useNavigate } from 'react-router-dom'

const LandDetails = () => {
  const dispatch = useDispatch()
  const AxiosInterceptor = Interceptor()
  const classes = useStyles()
  //   const navigate = useNavigate()
  const {
    id,
    street,
    parcelOrLotNumber,
    customerId,
    districtId,
    urbanAreaId,
    registrationSectionId,
    landAppraisal,
    landAppraisalName,
    landTitle,
    landTitleName,
    purchaseAgreement,
    purchaseAgreementName
  } = useSelector((state) => state.landDocs)

  const [Files, setFiles] = useState([])
  const [schema, setSchema] = useState({})

  const validationSchema = yup.object(schema)

  const formik = useFormik({
    initialValues: {
      districtId: null,
      cityTownVillage: null,
      registrationSectionId: null,
      landAppraisal: null,
      landAppraisalName: null,
      landTitle: null,
      landTitleName: null,
      street: null,
      parcelOrLotNumber: null,
      purchaseAgreementFile: null,
      purchaseAgreementName: null
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const uploadFiles = async (values) => {
    let landDocsData = {
      districtId: await values.districtId,
      urbanAreaId: await values.cityTownVillage,
      registrationSectionId: await values.registrationSectionId,
      landAppraisal: await convertBase64(values.landAppraisal),
      landTitle: await convertBase64(values.landTitle),
      purchaseAgreement: await convertBase64(values.purchaseAgreement),
      street: await values.street,
      parcelOrLotNumber: await values.parcelOrLotNumber,
      landTitleName: values.landTitle?.name,
      landAppraisalName: values.landAppraisal?.name,
      purchaseAgreementName: values.purchaseAgreement?.name
    }

    dispatch(
      hasSecureLandDocs({
        districtId: landDocsData.districtId,
        urbanAreaId: landDocsData.urbanAreaId,
        registrationSectionId: landDocsData.registrationSectionId,
        landAppraisal: landDocsData.landAppraisal,
        landAppraisalName: landDocsData.landAppraisalName,
        landTitle: landDocsData.landTitle,
        landTitleName: landDocsData.landTitleName,
        purchaseAgreement: landDocsData.purchaseAgreement,
        street: landDocsData.street,
        parcelOrLotNumber: landDocsData.parcelOrLotNumber,
        purchaseAgreementName: landDocsData.purchaseAgreementName
      })
    )
  }

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  const getMissingDocs = async () => {
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.get('/schedule-appointment-missing-docs').then(
      (response) => {
        setFiles(response.data)
      }
    ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const getData = async () => {
    setTimeout(async function () {
      dispatch({ type: Actions.LOADING, payload: true })
      return Promise.all([getMissingDocs()])
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    }, 3000)
  }

  const handleSubmit = (values) => {
    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.get('/schedule-appointment-missing-docs').then(
      (response) => {
        // if (response.data?.monthlyCreditObligations) {
        formik.setSubmitting(false)
        // sendData(true)
        // setsetErrorMessageCredit(false)
        //   console.log(values)

        uploadFiles(values)
        //   navigate('/Customer/monthlyIncome')
        // } else {
        // formik.setSubmitting(false)
        // setsetErrorMessageCredit(true)
        return
        // }
      }
    ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    // console.log(values)
    // return
  }

  useEffect(() => {
    let shape = {}
    if (!Files?.districtId) {
      shape.districtId = yup.mixed().required('This is required')
    }

    if (!Files?.cityTownVillage) {
      shape.cityTownVillage = yup.mixed().required('This is required')
    }

    if (!Files?.registrationSectionId) {
      shape.registrationSectionId = yup.mixed().required('This is required')
    }

    shape.landTitle = yup
      .mixed()
      // .required('This is required')
      .test('file-size', 'The file cannot exceed 2MB ', function (value) {
        if (value) {
          var filesize = (value?.size / 1024 / 1024).toFixed(4)
          return filesize <= 2
        } else {
          return true
        }
      })

    shape.landAppraisal = yup
      .mixed()
      // .required('This is required')
      .test('file-size', 'The file cannot exceed 2MB ', function (value) {
        if (value) {
          var filesize = (value?.size / 1024 / 1024).toFixed(4)
          return filesize <= 2
        } else {
          return true
        }
      })

    if (!Files?.purchaseAgreement) {
      shape.purchaseAgreement = yup
        .mixed()
        // .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    setSchema(shape)
  }, [Files])

  useEffect(() => {
    getData()

    dispatch(hasSecureLandDocs({}))
  }, [])

  return (
    <Box className={classes.Container}>
      <Grid
        container
        sx={{
          '@media (max-width: 800px)': {
            flexDirection: 'column'
          }
        }}
      >
        <Grid xs={12}>
          <Typography className={classes.Titles}>
            PLEASE COMPLETE THE FOLLOWING PROPERTY INFORMATION FIELDS FOR THE
            LAND BEING PURCHASED
          </Typography>
          <Typography className={classes.RedTitle}>
            *Mandatory Fields
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          <FormikProvider value={formik}>
            <LandLoanData
              Files={Files}
              formik={formik}
              landDetails={{
                id,
                street,
                parcelOrLotNumber,
                customerId,
                districtId,
                urbanAreaId,
                registrationSectionId,
                landAppraisal,
                landAppraisalName,
                landTitle,
                landTitleName,
                purchaseAgreement,
                purchaseAgreementName
              }}
            />
          </FormikProvider>
        </Grid>
      </Grid>
      <ArrowButton
        // onClickBackBtn={() => handleBackBtn()}
        onClick={() => {
          formik.submitForm()
        }}
        link={'/Customer/monthlyIncome'}
        back='/Customer/loan-calculation'
        disabled={
          (Files.districtId &&
            Files.cityTownVillage &&
            Files.registrationSectionId) ||
          (formik.values.districtId &&
            formik.values.cityTownVillage &&
            formik.values.registrationSectionId)
            ? false
            : true
        }
        msg='Fill required fields.'
      />
    </Box>
  )
}

export default LandDetails
