import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../../auth/jwt/JwtCreditCard'

export const hasApplicationInProgress = createAsyncThunk('appSelectApplication/hasApplicationInProgress', async () => {
  const response = await UseJwt.hasApplicationInProgress()
  return response.data
})

export const hasCreditLimitIncreaseAvailable= createAsyncThunk(
  'appSelectApplication/hasCreditLimitIncreaseAvailable',
  async () => {
    const request = await UseJwt.getUserCreditCardInfo()
    return request.data
  }
)

export const startLimitIncrease = createAsyncThunk('appSelectApplication/startLimitIncrease', async (params) => {
  const response = await UseJwt.startLimitIncrease(params)
  return response.data
})

export const appSelectApplication = createSlice({
  name: 'appSelectApplication',
  initialState: {
    applicationData: {},
    selectedApplication: '',
    hasCurrentApplicationInProgress: true,
    qualifyForLimitIncrease: false,
    hasCreditCardWithBBL: false,
    criterialOfferStatus: null,
    pending: true,
    conditions: {
      canApplyForLimitIncrease: false, 
      canAcceptCreditCardOffer: false, 
      canViewApplications: false, 
      canApplyforNewCreditCard:false,
    }
  },
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(hasApplicationInProgress.fulfilled, (state, action) => {
        state.hasCurrentApplicationInProgress = action.payload?.hasApplicationInProgress
        state.qualifyForLimitIncrease = action.payload?.qualifyForLimitIncrease
        state.applicationData = action.payload
        state.pending = false
      })
      .addCase(hasApplicationInProgress.rejected, (state, action) => {
        state.hasCurrentApplicationInProgress = true
        state.pending = false
      })
      .addCase(hasCreditLimitIncreaseAvailable.fulfilled, (state, action) => {
        state.conditions = {
          ...state.conditions,
          canApplyForLimitIncrease: !!action.payload?.qualifyForLimitIncrease
        }
        state.pending = false
      })
})

export default appSelectApplication.reducer