import React from 'react'
import FormCreditObligation from './FormCreditObligation'
import { useDispatch, useSelector } from 'react-redux'
import { getMonthlyExpensesTypes, getMyMonthlyExpenses } from '../store'
import { Grid, Box } from '@material-ui/core'

const MonthlyLoanPayment = () => {
  const dispatch = useDispatch()
  const { monthlyExpensesType } = useSelector(
    (state) => state.ccMonthlyExpenses
  )

  React.useEffect(() => {
    dispatch(getMyMonthlyExpenses())
    dispatch(getMonthlyExpensesTypes())
  }, [dispatch])

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {monthlyExpensesType.map((item, index) => (
          <Grid item xs={12} sm={12} md={12} key={index}>
            <FormCreditObligation configuration={item} index={index} typeID={item.documentTypeId} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default MonthlyLoanPayment