import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ssnDocumentPending } from '../store'
import {
  deleteDocument,
  uploadDocument
} from '../../../context/global-application'
import UploadFile from '../../../../Components/UploadFile'

const UploadSSN = () => {
  const dispatch = useDispatch()
  const { customerHasSSB } = useSelector((state) => state.ccUploadDocuments)

  React.useEffect(() => {
    dispatch(ssnDocumentPending())
  }, [])

  const deleteFile = (file) => {
    dispatch(deleteDocument({ id: file.id, module: file?.docTypeDetails?.module || 'personaldocs' }))
  }

  const saveFile = async (file) => {
    const params = {
      documentType: 'ssn',
      documentName: file.filename,
      documentFile: file.base64,
      module: 'personaldocs'
      // moduleId: item.id
    }
    return dispatch(uploadDocument(params))
  }

  return (
    customerHasSSB?.uploadIsRequired && (
      <UploadFile
        label={'Upload SSN*'}
        onChange={async (file) => await saveFile(file)}
        placeholder={`Upload file for SSN`}
        onDelete={(document) => deleteFile(document)}
      />
    )
  )
}

export default UploadSSN
