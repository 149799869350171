import { formatBelizeCurrency } from '../Utils/utils'


const createAdapter = (fields = []) => {
  return (data) => {
    const base = {
      id: data?.id || null,
      key: data?.key || null,
      module: data?.module || null,
      docKey: data?.docKey || null,
      label: `${data?.extraDetails?.ammount ? formatBelizeCurrency(data.extraDetails.ammount) : ''} - ${data?.extraDetails?.bankName || ''}`,
    }
    fields.forEach((field) => {
      if (data[field] !== undefined) {
        base[field] = data[field]
      }
    })

    return base
  }
}

export const USER_TYPE = {
  Individual: 'Individual',
  Business: 'Business'
}

export const APPLICATION_STEP = {
  'personal-information-review': {
    path: '/credit-card/personal-information-review',
    key: 'personal-information-review'
  },
  'limit-increase-personal-information-review': {
    path: '/limit-increase/personal-information-review',
    key: 'limit-increase-personal-information-review'
  },
  'limit-increase-request-cc': {
    path: '/limit-increase/request',
    key: 'limit-increase-request-cc'
  },
  'credit-limit-request-cc': {
    path: '/credit-card/credit-limit-request',
    key: 'credit-limit-request-cc'
  },
  'additional-applicants-cc': {
    path: '/credit-card/additional-applicants',
    key: 'additional-applicants-cc'
  },
  'monthly-expenses-cc': {
    path: '/credit-card/monthly-expenses',
    key: 'monthly-expenses-cc'
  },
  'monthly-income-cc': {
    path: '/credit-card/monthly-income',
    key: 'monthly-income-cc'
  },
  'proof-of-income-cc': {
    path: '/credit-card/proof-of-income',
    key: 'proof-of-income-cc'
  },
  'upload-documents-cc': {
    path: '/credit-card/upload-documents',
    key: 'upload-documents-cc'
  },
  'pending-signature-online-cc': {
    path: '/credit-card/sign-documents',
    key: 'pending-signature-online-cc'
  },
  'pending-signature-branch-cc': {
    path: '/credit-card/pending-signature-branch',
    key: 'pending-signature-branch-cc'
  },
  'standing-order-cc': {
    path: '/credit-card/standing-order',
    key: 'standing-order-cc'
  },
  'change-schedule-appointment-cc': {
    path: '/credit-card/change-schedule-appointment',
    key: 'change-schedule-appointment-cc'
  }
}