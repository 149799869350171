import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  //* crearemos el diseNos de la caja tooltip que pareza comouna tarjeta de chat con un borde redondeado y una de las esquinas apuntandoal infoicon
  groupButton: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20
  },
  titleCard: {
    fontSize: '2rem',
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 700,
    position: 'relative',
    zIndex: 100,
    margin:0,
  },
  mainCardStyle: {
    margin: '16px 2px 0 12px',
    width: '45%',
    // backgroundColor: isActive ? "#53AF32" : "white",
    // border: '1px solid #53af32',
    paddingTop: '1.5rem',
    paddingLeft: '1.5rem',
    paddingBottom: '1.5rem',
    textDecoration: 'none',
    color: '#fff',
    // animation: 'ease-in-out 0.5s',
    '@media (max-width: 900px)': {
      width: '93%',
      paddingTop: 0
    },

    "@media (max-width: 700px)": {
      margin: '18px 7px 0 7px',
    },

    "@media (max-width: 420px)": {
      margin: '24px 0px 0 0px',
    },
  },
  btnVariation: {
    border: '1px solid #fff',
    display: 'inline-block',
    padding: '0.5rem 1rem',
    borderRadius: '2rem',
    fontFamily: theme.fonts.family.secondary,
    fontSize: '12.2px',
    fontWeight: '600',
    position: 'relative',
    zIndex: 100,
    // '&:hover': {
    //   background: 'white',
    //   color: '#275DA6',
    //   fill: '#275DA6'
    // },
  },
  
}));

